import { Travel, TravelReceive } from "types/data/Travel";
import { url, treatError, defaultHeaders } from "../../api";

export const create = async (
  travel: Partial<Travel | TravelReceive>
): Promise<Travel> => {
  const df = defaultHeaders();
  const headers = { ...df, mode: "no-cors" };
  return fetch(url(`travels`), {
    headers,
    method: "POST",
    body: JSON.stringify(travel),
  }).then((r) => {
    if (r.ok) return r.json();
    throw new Error(treatError(r));
  });
};

export default create;
