import { TravelStatusEnum } from "types/data/Travel";
import { formatedDateOfRequest, getDiffBetweenDatesInDays } from "./formatters";

const handleSetStatus = (
  checkin: string | undefined,
  checkout: string | undefined
) => {
  const formatCheckin = formatedDateOfRequest(checkin);
  const formatCheckout = formatedDateOfRequest(checkout);
  const todayDate = new Date();
  const todayToCheckinDiference = getDiffBetweenDatesInDays(
    todayDate,
    formatCheckin
  );
  const todayToCheckoutDiference = getDiffBetweenDatesInDays(
    todayDate,
    formatCheckout
  );
  if (todayToCheckoutDiference < 0) {
    return TravelStatusEnum.REALIZADA;
  }
  if (todayToCheckinDiference < 0 && todayToCheckoutDiference > 0) {
    return TravelStatusEnum.PROGRESSO;
  }
  if (todayToCheckinDiference === 0 && todayToCheckoutDiference > 0) {
    return TravelStatusEnum.PROGRESSO;
  }
  if (todayToCheckinDiference > 0) {
    return TravelStatusEnum.AGENDADA;
  }
};
export { handleSetStatus };
