import { signIn } from "api/requests/admin/signIn";
import { AdminData } from "types/data/Admin";
import { useState, useEffect, useCallback } from "react";
import { useIsLoggedContext } from "context/IsLoggedContext";
export const storeLocalUser = (userInfo: AdminData) =>
  window.localStorage.setItem("userInfo", JSON.stringify(userInfo));

export const loadLocalUser = (): AdminData | null => {
  let AdminData = window.localStorage.getItem("userInfo");
  if (!AdminData) return null;
  return JSON.parse(AdminData);
};

export const cleanLocalUser = () => window.localStorage.removeItem("userInfo");

export const isTokenValid = (AdminData?: AdminData) => {
  if (!AdminData) return false;
  return new Date(AdminData.expires_at).valueOf() > new Date().valueOf();
};

export const useUser = () => {
  const {setIsLogged} = useIsLoggedContext()
  const [loggedin, setloggedin] = useState<boolean | null>(null);
  const [AdminData, setAdminData] = useState<AdminData | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const AdminData = loadLocalUser();
    const isUserValid = AdminData && isTokenValid(AdminData);
    setloggedin(Boolean(isUserValid));
    setAdminData(AdminData || undefined);
    setLoading(false);
  }, []);

  const impersonate =  useCallback((AdminData: AdminData): boolean => {
    setIsLogged(true)
    setloggedin(true);
    storeLocalUser(AdminData);
    setAdminData(AdminData);
    setLoading(false);
    return true;
  }, []);

  const login = useCallback(
    async (email: string, pass: string): Promise<boolean> => {
      setLoading(true);
      return signIn({ email, pass }).then(impersonate);
    },
    [impersonate]
  );

  const logout = useCallback((): boolean => {
    setLoading(true);
    cleanLocalUser();
    setloggedin(false);
    setAdminData(undefined);
    setLoading(false);
    return true;
  }, []);
  return { loggedin, AdminData, login, loading, logout, impersonate };
};
export default useUser;
