import styled from "styled-components";
export const Wrapper = styled.div`
  .react-date-picker__wrapper {
    border: none;
  }
  svg {
    width: 15px;
  }
  .react-date-picker__inputGroup__input {
    font-weight: bold;
    font-family: ${({ theme }) => theme.font.familly};
  }
  .input-date {
    border: none;
  }
`;
