import styled from "styled-components";

export const Container = styled.div`
  overflow-y: scroll;
  width: 100%;
  height: 100%;
  h1 {
    font-size: 3rem;
  }
`;

export const TableContainer = styled.div`
  display: flex;  
  height: 500px;
  width: 98%;
  background: white;
  border: "none";
  border-radius: 10;
  .MuiDataGrid-columnHeaderTitle {
    font-weight: 600;
  }
`;

export const ImgContainer = styled.div`
  border-radius: 100px;
  width: 80px;
  height: 80px;
  border-color: rgb(241, 137, 110);
  border-width: 2px;
  border-style: solid;
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`


export const Header = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  h1 {
    font-size: 30px;
  }
`;
export const SearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;
  align-items: center;
  max-width: 400px;
  svg {
    margin-right: -10px;
    width: 20px;
  }
`;

export const SeachField = styled.input`
  width: 100%;
  border: none;
  border-bottom: 1px solid black;
  outline: none;
  background-color: transparent;
  padding: 10px;
`;
export const ProfilePicture = styled.img`
  border-radius: 50%;
  object-fit: cover;
  width: 120px;
  height: 120px;
  margin-top: 7px;
`;

export const ContentForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
  
`;

export const TopContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

export const BirthDateContent = styled.div`
  display: flex;
  flex-direction: column;
  
`;

export const DateInputs = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  
`;

export const Section = styled.div`
  display: flex;
  flex-direction: row;
  gap: 30px;
  max-width: fit-content;
`;

export const PhoneContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
`;

export const EmergencySection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  max-width: fit-content;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
`;

export const ButtonsSection = styled.div`
  gap: 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-self: flex-end;
  button {
    width: 100%;
    padding: 10px 30px;
  }
`;
