export const themes = {
  font: {
    familly: "'Poppins', sans-serif",
  },

  palette: {
    background: "#FEF6E9",

    orange: {
      main: "#F1896E",
      _100: "#C74C39",
      _200: "#F2E8D6",
    },
    green: {
      main: "#075D68",
      _100: "#CFE3E5",
      _200: "#0B8B9C",
    },
    grey: {
      main: "#383838",
      _100: "#505050",
      _200: "#B1A89A",
      _300: "#646464",
    },
    white: {
      main: "#FFFFFF",
    },
  },
};
