import React, { useEffect, useState } from "react";
import { masksRecord, IMaskOptions } from "./types";
import { InputLabel, StyledInput, Container, InputContainer } from "../styles";
import {
  UseFormSetValue,
  UseFormRegisterReturn,
  UseFormReset,
  UseFormGetValues,
} from "react-hook-form";
import { iError } from "../types";
import ErrorMessage from "../ErrorMessage";


type GenericSelectProps = {
  [x: string]: any;
  placeholder: string | undefined;
  option: IMaskOptions;
  setValue?: UseFormSetValue<any>;
  getValue?: UseFormGetValues<any>;
  reset?: UseFormReset<any>;
  register?: UseFormRegisterReturn<any>;
  error?: iError;
  cleanFields?: boolean;
  setCleanFields?: (active: boolean) => void;
  defaultValue?: string;
  disable?: boolean;
};

export const CustomInputMask = ({
  id,
  option,
  setValue,
  register,
  error,
  isReset,
  cleanFields,
  setCleanFields,
  disable,
  getValue,
  placeholder,
  defaultValue = "",
  label,
  ...rest
}: GenericSelectProps) => {
  const firstRender = React.useRef(true);
  const inputLabel = label ? `${label}:` : '';
  
  const formatValue = (str: string) => (id ? masksRecord[option](str) : str);
  const [maskedValue, setMaskedValue] = useState<string>(formatValue(defaultValue));
  
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newValue: string = formatValue(e.currentTarget.value);
    setMaskedValue(newValue);
    if (setValue) {
      setValue(id, maskedValue);
    }
  };

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      if (setValue) {
        setValue(id, "");
      }
      return;
    }

    if (setValue) {
      setValue(id, maskedValue);
    }
    if (cleanFields) {
      setMaskedValue("");
      if (setCleanFields) {
        setCleanFields(false);
      }
    }
  }, [id, maskedValue, setValue, cleanFields]);

  useEffect(() => {
    if (setValue && defaultValue) {
      if (defaultValue?.length > 0) {
        setValue(id, defaultValue);
      }
    }
  }, [defaultValue, setValue]);

  return (
    <InputContainer>
      <InputLabel>{inputLabel}</InputLabel>
      <Container error={error?.message} {...rest}>
        <StyledInput
          initial={{ opacity: 0, scale: 0.5, x: 30 }}
          animate={{ opacity: 1, scale: 1, x: 0 }}
          transition={{ type: "spring", stiffness: 100 }}
          disabled={disable}
          {...register}
          {...rest}
          value={maskedValue}
          placeholder={placeholder}
          onChange={handleChange}
        />
      </Container>
      {error && <ErrorMessage error={error} />}

    </InputContainer>
  );
};

export default CustomInputMask;
