import { Error } from "@mui/icons-material";
import { AnimatePresence } from "framer-motion";
import React from "react";
import { ErrorArrow, ErrorContainer, ErrorModal } from "./style";

interface iModalError {
  error: string | undefined;
}

const ModalError = ({ error }: iModalError) => {
  return (
    <AnimatePresence>
      {error && (
        <ErrorContainer
          initial={{
            opacity: 0,
            x: -10,
          }}
          exit={{
            opacity: 0,
            x: 10,
          }}
          animate={{
            x: 0,
            opacity: 1,
          }}
        >
          <ErrorModal>
            <Error className="icon" />
            <p>{error}</p>
          </ErrorModal>
          <ErrorArrow />
        </ErrorContainer>
      )}
    </AnimatePresence>
  );
};

export default ModalError;
