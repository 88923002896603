import { Travel } from "types/data/Travel";
import { url, treatError, defaultHeaders } from "../../api";

export const search = async (qs?: {
  [key: string]: number;
}): Promise<Array<Travel>> => {
  const df = defaultHeaders();
  const headers = { ...df, mode: "no-cors" };

  return fetch(url(`travels/search`, qs), {
    headers,
    method: "GET",
  }).then((r) => {
    if (r.ok) return r.json();
    throw new Error(treatError(r));
  });
};

export default search;
