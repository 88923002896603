import styled from "styled-components";
export const ActivitiesSection = styled.form`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 90%;
  .quill {
    width: 100%;
    height: 400px;
    margin-bottom: 20px
  }
  .ql-editor {
    background: white;
  }
  .css-1qqsdnr-MuiAutocomplete-root {
    background: white;
    border-radius: 20px;
    margin-top: 1rem;
  }
  .css-1qqsdnr-MuiAutocomplete-root
    .MuiAutocomplete-inputRoot
    .MuiAutocomplete-input {
  }
  .css-1qqsdnr-MuiAutocomplete-root .MuiAutocomplete-inputRoot {
    box-shadow: inset 0px 4px 4px rgb(0 0 0 / 10%);
    border-radius: 20px;
    padding: 15px;
  }
  .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border: none;
    box-shadow: inset 0px 4px 4px rgb(0 0 0 / 10%);
    padding: 15px;
  }
  .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
    font-weight: 600;
    font-family: ${({ theme }) => theme.font.familly};
    margin-top: 5px;
    margin-top: 5px;
  }
  .MuiDataGrid-cellContent{
    font-size: 1.5rem;
    font-family: ${({ theme }) => theme.font.familly};
  }

  .MuiDataGrid-columnHeaderTitle{
    font-size: 1.8rem;
    font-family: ${({ theme }) => theme.font.familly};
  }
  
  `;
export const SectionTitle = styled.h1`
  font-size: 22px;
  font-weight: 500;
`;
export const SaveWrapper = styled.div`
  button {
    
    width: 250px;
  }
`;
export const SwitchWrapper = styled.div`
  max-width: 300px;
  margin-bottom: 20px;
`;
export const CommentWrapper = styled.div`
  max-width: 600px;
`;
export const RatingButton = styled.div`
  button {
    max-width: 250px;
    border-radius: 10px;
    padding: 0 0.5rem;
  }
`;
export const EditButton = styled.div`
  background: ${({ theme }) => theme.palette.orange._100};
  max-width: 350px;
  border-radius: 10px;
  height: 50px;
  padding: 0 1.5rem;
  padding: 0 1.5rem;
  display: flex;
  align-items: center;
  border-radius: 10px;
  transition: 0.5s;
  gap: 8px;
  :hover {
    background: ${({ theme }) => theme.palette.orange.main};
    button {
      background: ${({ theme }) => theme.palette.orange.main};
    }
  }
  svg {
    width: 20px;
    path {
      fill: white;
    }
  }
  span {
    color: ${({ theme }) => theme.palette.white.main};
    font-weight: bold;
  }
`;
export const ErrorAndInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
  
`;