import { AdminData, LoginData } from "types/data/Admin";
import { url, treatError } from "../../api";

export const signIn = async (userData: LoginData): Promise<AdminData> => {
  return fetch(url("admin/signin"), {
    headers: { "Content-Type": "application/json", mode: "no-cors" },
    method: "POST",
    body: JSON.stringify(userData),
  }).then((r) => {
    if (r.ok) return r.json();
    throw new Error(treatError(r));
  });
};

export default signIn;
