import { GridColDef } from "@mui/x-data-grid";


export const clientColumns: GridColDef[] = [
  { field: "fullname", headerName: "Nome do cliente", width: 200, filterable: true },
  { field: "email", headerName: "E-mail", width: 200 },
  { field: "phone1", headerName: "Telefone", width: 200 },
  { field: "emergencyname", headerName: "Contato de Emergência", width: 200 },
  { field: "emergencyphone", headerName: "Telefone Emergência", width: 220 },  
  { field: "emergencyemail", headerName: "Email de Emergência", width: 250 },  
];
