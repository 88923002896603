import styled from "styled-components";

export const Container = styled.div`
  width: 350px;
  height: -webkit-fill-available;
  padding: 30px 20px;
  border-radius: 10px;

  user-select: none;

  background: ${({ theme }) => theme.palette.white.main};
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  display: flex;
  gap: 30px;
  flex-direction: column;
  justify-content: space-between;
`;

export const TopContent = styled.div`
  width: auto;

  img {
    width: 100%;
  }
`;

export const Content = styled.div`
  display: flex;
  overflow-y: scroll;
  flex-direction: column;

  gap: 10px;

  text-transform: capitalize;

  height: 75%;
  padding-right: 5px;

  ::-webkit-scrollbar {
    width: 3px;
  }

  ::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.02);
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: ${({ theme }) => theme.palette.orange.main};
  }
`;

export const BottomContainer = styled.div``;
