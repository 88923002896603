import styled from "styled-components";
import { motion } from "framer-motion";
export const Container = styled(motion.div)<{
  checked?: boolean;
}>`
  width: 120px;
  height: 35px;
  padding-left: 5px;
  margin: 0px 4px;
  border-radius: 5px;

  display: flex;
  align-items: center;
`;
export const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  overflow: hidden;
  white-space: nowrap;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
`;
export const StyledCheckbox = styled(motion.label)<{
  checked?: boolean;
}>`
  width: 23px;
  height: 23px;
  transition: 0.4s;
  margin-right: 6px;
  border-radius: 10px;
  border: 2px solid rgba(0, 0, 0, 0.24);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background: ${({ checked }) => (checked ? "#075D68" : "#f6f6f6")};
  display: flex;
  justify-content: center;
  align-items: center;
  & svg {
    display: ${({ checked }) => (checked ? "block" : "none")};
    width: 20px;
    & path {
      fill: white;
    }
  }
`;
