import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';

import { logoIMG } from 'assets/img';
import { passwordICON, profileICON } from 'assets/svg/asset';

import Box from 'components/Box';
import signIn from 'api/requests/admin/signIn';
import Checkbox from 'components/Inputs/Checkbox';
import { Button } from 'components/Buttons';
import { Input, InputPassword } from 'components/Inputs';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Wallpaper, FormContainer, ButtonContainer, Divider } from './styles';

import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import useUser from 'hooks/useUser';

const ClientsIcon = () => (
	<img
		src={profileICON}
		alt='icone do perfil de usuário'
	/>
);

const PassIcon = () => (
	<img
		src={passwordICON}
		alt='icone de senha do usuário'
	/>
);

interface IFormValues {
	email: string;
	password: string;
}

let timmer: number | NodeJS.Timeout | null | undefined = null;

const Login = () => {
	const { login } = useUser();
	const navigate = useNavigate();

	const [check, setCheck] = React.useState(false);

	const {
		register,
		handleSubmit,
		formState: { errors }
	} = useForm<IFormValues>();

	const onSubmit: SubmitHandler<IFormValues> = async (data) => {
		try {
			login(data.email, data.password);

			const response = await signIn({
				email: data.email,
				pass: data.password
			});

			if (response.id) {
				toast.success('Login Efetuado com Sucesso');
				navigate('/');
			} else {
				toast.error('deu errado');
			}
		} catch (e) {
			toast.error('Desculpe, isso não funcionou :( \n\n Tente novamente mais tarde, ou procure um administrador');
		} finally {
			timmer = setTimeout(() => {}, 1500);
		}
	};

	React.useEffect(() => {
		return () => {
			window.clearTimeout(timmer as any);
		};
	}, []);

	return (
		<AnimatePresence>
			<FormContainer onSubmit={handleSubmit(onSubmit)}>
				<Box>
					<Wallpaper
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						transition={{ delay: 0.6 }}>
						<motion.img
							src={logoIMG}
							alt='logo da voya haus'
						/>
					</Wallpaper>
					<Input
						id='email'
						placeholder='E-mail'
						customIcon={<ClientsIcon />}
						register={register('email', { required: true })}
						error={{
							message: errors.email?.message,
							showMessage: true
						}}
					/>

					<InputPassword
						id='password'
						placeholder='Senha'
						customIcon={<PassIcon />}
						register={register('password', { required: true })}
						error={{
							showModalMessage: true,
							message: errors.password?.message
						}}
					/>

					<ButtonContainer>
						<Checkbox
							text='Mantenha-me conectado'
							isChecked={check}
							setIsChecked={setCheck}
						/>
						<Button buttonType='submit'>Entrar</Button>
					</ButtonContainer>

					<motion.div
						initial={{
							opacity: 0,
							y: 110
						}}
						animate={{
							opacity: 1,
							y: 0
						}}
						transition={{
							delay: 1
						}}>
						<Divider
							initial={{
								width: 0
							}}
							animate={{
								width: '100%'
							}}
							transition={{
								delay: 1.5
							}}
						/>
					</motion.div>
				</Box>
			</FormContainer>
		</AnimatePresence>
	);
};

export default Login;
