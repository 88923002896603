import { tip } from "types/data/Tip";
import { url, treatError, defaultHeaders } from "../../api";

export const update = async (tip: Partial<tip>): Promise<tip> => {
  const defaultHeadersReceive = defaultHeaders();
  const headers = { ...defaultHeadersReceive, mode: "no-cors" };

  return fetch(url(`travels/tips/${tip.id}`), {
    headers,
    method: "PATCH",
    body: JSON.stringify(tip),
  }).then((r) => {
    if (r.ok) return r.json();
    throw new Error(treatError(r));
  });
};

export default update;
