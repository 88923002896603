import { loadLocalUser } from "hooks/useUser";

export const url = (
  endpoint: string,
  qs?: { [key: string]: number }
): string => {
  var mainUrl = `${process.env.REACT_APP_BACKEND_ADDRESS}/${endpoint}`;
  if (qs) {
    mainUrl += "?";
    Object.entries(qs).forEach(([key, value], index) => {
      if (index !== 0) mainUrl += "&";
      mainUrl += `${key}=${value}`;
    });
  }
  return mainUrl;
};

export const treatError = (response: Response): string =>
  `${response.status}: ${response.statusText}`;

export const defaultHeaders = (): {
  "Content-Type": string;
  Authorization?: string;
} => {
  const headers = {
    "Content-Type": "application/json",
  };
  const token = loadLocalUser()?.token;
  if (token) return { ...headers, Authorization: `Bearer ${token}` };
  return headers;
};
