import * as InputMaskOptions from "../../../utils/options";

export type IMaskOptions =
  | "money"
  | "cpf"
  | "cnpj"
  | "cpfOrCnpj"
  | "cep"
  | "phone"
  | "hour"
  | "agency"
  | "account"
  | "day"
  | "month"
  | "year"
  | "date";

export const masksRecord: Record<IMaskOptions, (value: string) => string> = {
  money: InputMaskOptions.currency,
  cpf: InputMaskOptions.cpf,
  cnpj: InputMaskOptions.cnpj,
  cpfOrCnpj: InputMaskOptions.cpfOrCnpj,
  cep: InputMaskOptions.cep,
  phone: InputMaskOptions.phone,
  hour: InputMaskOptions.hour,
  agency: InputMaskOptions.agency,
  account: InputMaskOptions.account,
  day: InputMaskOptions.day,
  month: InputMaskOptions.month,
  year: InputMaskOptions.year,
  date: InputMaskOptions.date,
};
