import React from "react";

import { AnimatePresence } from "framer-motion";
import { CircularProgress } from "@mui/material";

import { Container, Backdrop, Letter } from "./style";

const letters = ["C", "a", "r", "r", "e", "g", "a", "n", "d", "o"];

interface iLoading {
  open: boolean;
}

const Loading = ({ open }: iLoading) => {
  return (
    <AnimatePresence>
      {open && (
        <>
          <Container
            initial={{ top: "60%", opacity: 0 }}
            animate={{ top: "", opacity: 1 }}
          >
            <CircularProgress style={{ color: "#F1896E" }} />
            <Letter>
              {letters.map((letter) => (
                <h1 key={letter}>{letter}</h1>
              ))}
            </Letter>
          </Container>
          <Backdrop />
        </>
      )}
    </AnimatePresence>
  );
};

export default Loading;
