import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
`;

export const Content = styled.div``;

export const Header = styled.div`
  display: flex;
  flex-direction: column;

  h1 {
    font-size: 1.4rem;
    margin-bottom: -10px;
  }
`;
