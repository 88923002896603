import ListItem from "components/ListItem";
import { useState } from "react";
import {
  AddListItem,
  ChecklistContainer,
  ChecklistHeader,
} from "../style";
import Switch from 'components/Inputs/Switch';
import { ChecklistProps } from "../types";
import { DeleteSVG, PlusSVG } from "assets/svg/components";
import { Button, } from "components/Buttons";
import { IconButton } from "pages/Clients/List/Table/styles";
import { MoreItemWrapper } from "pages/Travel/Register/style";
import { create as createItem } from "api/requests/checklist/item/create";
import { useMutation } from "react-query";
import { checklist_item } from "types/data/ChecklistItem";
import { toast } from "react-toastify";
import { queryClient } from "api/queryClient";
import { remove } from "api/requests/checklist/item/remove";
import { update } from "api/requests/checklist/item/update";
import { Pen } from "assets/svg/components/Pen";
import { update as updateChecklistHeaderRequest } from "api/requests/checklist/header/update";
import { checklist_header } from "types/data/ChecklistHeader";
import Section from "components/Section/Section";

import { ListContainer } from "./styles";
import { Container, InputContainer, StyledInput } from "components/Inputs/styles";

type EditChecklistProps = {
  setEditValue: Function;
  editValue: string;
  setIsEditHeader: Function;
  handleSubmitEditHeader: Function;
};
const EditChecklistContainer = ({
  editValue,
  setEditValue,
  setIsEditHeader,
  handleSubmitEditHeader,
}: EditChecklistProps) => {

  const handleSubmit = () => {
    setIsEditHeader(false);
    handleSubmitEditHeader(editValue);
  };
  return (
    <Section>
      <Container color="white" >
        <StyledInput
          initial={{ opacity: 0, scale: 0.5, x: 30 }}
          animate={{ opacity: 1, scale: 1, x: 0 }}
          transition={{ type: "spring", stiffness: 100 }}
          onChange={(e) => setEditValue(e.target.value)}
          value={editValue}
          type="text"
        />

      </Container>
      <Button onClick={handleSubmit}>Salvar</Button>
    </Section>
  );
};
export const CheckListWrapper = ({
  handleMoveItemDown,
  handleMoveItemUp,
  removeChecklist,
  props,
  id,
  checklistHeaderId,
  checklistItems,
}: ChecklistProps) => {

  const [items, setItems] = useState<checklist_item[]>();
  const [showInput, setShowInput] = useState(false);
  const [isEditHeader, setIsEditHeader] = useState(false);
  const [addInputValue, setAddInputValue] = useState<string>();
  const [isVisible, toggleVisibility] = useState(props.isvisible);
  const [editValue, setEditValue] = useState<string>(props.title);

  const handleSubmitEditHeader = (title: string) => {
    const formatItem: checklist_header = {
      title: title,
      id: checklistHeaderId,
      isvisible: isVisible,
      travel_id: props.travel_id
    };
    updateChecklistHeader.mutate(formatItem);
  };

  const checklistCreateMutation = useMutation({
    mutationFn: (item: checklist_item) => createItem(item),

    onSuccess: (data) => {
      queryClient.invalidateQueries("checklist-items");
      queryClient.invalidateQueries("checklist-headers");
      toast.success("Item da checklist criado");
    },
    onError: (error) => {
      toast.error(`Algo deu Errado ${error}`);
    },
  });

  const checklisItemRemove = useMutation({
    mutationFn: (id: number) => remove(id),
    onSuccess: (data) => {
      queryClient.invalidateQueries("checklist-headers");
      toast.success("Item deletado");
    },
    onError: (error) => {
      toast.error(`Algo deu Errado ${error}`);
    },
  });

  const moveItem = (id: number, direction: "up" | "down") => {
    switch (direction) {
      case "up":
        handleMoveItemUp(id, items, setItems);
        break;
      case "down":
        handleMoveItemDown(id, items, setItems);

        break;
      default:
        break;
    }
  };
  const updateChecklistItem = useMutation({
    mutationFn: (item: checklist_item) => update(item),
    onSuccess: (data) => {
      queryClient.invalidateQueries("checklist-items");
      queryClient.invalidateQueries("checklist-headers");
      toast.success("Item atualizado");
    },
    onError: (error) => {
      toast.error(`Algo deu Errado ${error}`);
    },
  });

  const updateChecklistHeader = useMutation({
    mutationFn: (item: checklist_header) => updateChecklistHeaderRequest(item),
    onSuccess: (data) => {
      queryClient.invalidateQueries("checklist-items");
      queryClient.invalidateQueries("checklist-headers");
      toast.success('Título da checklist atualizado');
    },
    onError: (error) => {
      toast.error(`Algo deu Errado ${error}`);
    },
  });

  const removeItem = (id: number) => {
    checklisItemRemove.mutate(id);
  };

  const handleAddNewListItem = () => {
    if (addInputValue) {
      if (checklistHeaderId) {
        const newItem: checklist_item = {
          title: addInputValue,
          ischecked: false,
          checklist_header_draft_id: checklistHeaderId,
        };
        checklistCreateMutation.mutate(newItem);
        setAddInputValue("");
        setShowInput(false);
      }
    } else {
      setShowInput(false);
    }
  };

  const checkboxIsChanged = (value: boolean, id: number) => {
    const filteredItem = checklistItems?.filter((item) => item.id === id);
    const newValue = !value;
    const newFilteredItem: checklist_item = {
      title: filteredItem![0].title,
      checklist_header_draft_id: filteredItem![0].checklist_header_draft_id,
      id: id,
      ischecked: newValue,
    };

    updateChecklistItem.mutate(newFilteredItem);
  };

  const handleEditHeaderChecklist = () => {
    setIsEditHeader(true);
  };

  const handleEditChecklistItem = (id: number, value?: string) => {
    if (value) {
      const items = checklistItems?.filter(function (item) {
        return item.id === id;
      });
      if (items) {
        const newItems = {
          ...items[0],
          title: value,
        };
        updateChecklistItem.mutate(newItems);
      }
    }
  };

  return (
    <ChecklistContainer >

      <ChecklistHeader>
        {isEditHeader ?
          <EditChecklistContainer
            handleSubmitEditHeader={handleSubmitEditHeader}
            editValue={editValue}
            setEditValue={setEditValue}
            setIsEditHeader={setIsEditHeader}
          /> : (<h1>{props.title}</h1>)}

        <Section>
          {!props.isvisible && !isEditHeader && <MoreItemWrapper small onClick={() => setShowInput(true)}>
            <Button color="green">
              <PlusSVG />
            </Button>
          </MoreItemWrapper>}

          {!props.isvisible && !isEditHeader &&
            (<IconButton type="button" onClick={handleEditHeaderChecklist}>
              <Pen />
              <p>Editar</p>
            </IconButton>)}

          {!props.isvisible && !isEditHeader && <IconButton type="button" onClick={() => {
            removeChecklist(checklistHeaderId ? checklistHeaderId : id)
          }
          }>
            <DeleteSVG />
            <p>Deletar</p>
          </IconButton>}

          <Switch
            disabled={!isEditHeader || props.isvisible}
            checked={isVisible}
            handleChange={() => toggleVisibility(!isVisible)}
            options={['Invisível', 'Visível']}
          />

        </Section>
      </ChecklistHeader>

      {checklistItems?.map((item, index) => {
        return (
          <ListContainer key={item.id}>
            {item.checklist_header_draft_id === checklistHeaderId && (
              <ListItem
                title={item.title}
                type="normal"
                handleMoveItem={moveItem}
                handleRemoveListItem={removeItem}
                itemId={item.id ? item.id : index}
                checkboxIsChange={checkboxIsChanged}
                checkValue={item.ischecked}
                handleEdit={handleEditChecklistItem}
                disabled={!isEditHeader || props.isvisible}
              />
            )}
          </ListContainer>
        );
      })}


      {showInput && (
        <AddListItem>
          <input
            onChange={(e) => setAddInputValue(e.target.value)}
            value={addInputValue}
            type="text"
          />
          <span onClick={() => handleAddNewListItem()}>
            <Button>adicionar</Button>
          </span>
        </AddListItem>
      )}
    </ChecklistContainer>
  );
};
