import { ReceiveUser } from "types/data/User";
import { url, treatError, defaultHeaders } from "../../api";

export const search = async (qs?: {
  [key: string]: number;
}): Promise<Array<ReceiveUser>> => {
  const df = defaultHeaders();
  const headers = { ...df, mode: "no-cors" };
  return fetch(url("users", qs), {
    headers,
    method: "GET",
  }).then((r) => {
    if (r.ok) return r.json();
    throw new Error(treatError(r));
  });
};

export default search;
