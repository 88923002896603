import { defaultHeaders, treatError, url } from "api/api";
import { checklist_header } from "types/data/ChecklistHeader";

export const create = async (
  checklistHeader: checklist_header
): Promise<checklist_header> => {
  const df = defaultHeaders();
  const headers = { ...df, mode: "no-cors" };
  return fetch(url(`checklist/draft/travel/${checklistHeader.travel_id}/header`), {
    headers,
    method: "POST",
    body: JSON.stringify(checklistHeader),
  }).then((r) => {
    if (r.ok) return r.json();
    throw new Error(treatError(r));
  });
};

export default create;
