import { Button } from "@mui/material";
import { motion } from "framer-motion";
import React from "react";

import { StyledButton } from "./styles";
import { IColor } from "./types";

interface IButton {
  children?: React.ReactNode;
  value?: string;
  color?: IColor;
  isSubmit?: Boolean;
  onClick?: Function;
  buttonType?: "button" | "submit" | "reset" | undefined;
}

const ButtonComponent = ({
  children,
  color = "orange",
  buttonType = undefined,
  onClick,
  value,
  ...rest
}: IButton) => {
  if (onClick) {
    return (
      <StyledButton
        color={color}
        {...rest}
        transition={{ type: "spring", stiffness: 100 }}
        whileTap={{ scale: 0.9 }}
      >
        <Button
          onClick={(event) => {
          event.preventDefault();
          onClick();
        }} type="submit">
          <motion.h1>{children}</motion.h1>
        </Button>
      </StyledButton>
    );
  }

  return (
    <StyledButton
      color={color}
      {...rest}
      transition={{ type: "spring", stiffness: 100 }}
      whileTap={{ scale: 0.9 }}
    >
      <Button type={buttonType}>
        <motion.h1>{children}</motion.h1>
      </Button>
    </StyledButton>
  );
};

export default ButtonComponent;
