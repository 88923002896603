import React from "react";

import { AnimatePresence } from "framer-motion";
import { TagButtonProps } from "./types";
import {
  InlineButton,
  InlineButtonLine,
  InlineButtonContainer,
} from "./styles";

const TagButton = ({
  children,
  size,
  color,
  onClick,
  bgColor,
}: TagButtonProps) => {
  const [show, setShow] = React.useState(false);

  if (onClick) {
    return (
      <InlineButtonContainer
        onMouseLeave={() => setShow(false)}
        onMouseEnter={() => setShow(false)}
        onClick={(event) => {
          event.preventDefault();
          onClick();
        }}
      >
        <InlineButton color={color} size={size}>
          {children}
        </InlineButton>
        <AnimatePresence>
          {show && (
            <>
              <InlineButtonLine
                bgColor={bgColor}
                exit={{ width: 0 }}
                whileTap={{ scale: 1.2 }}
                initial={{ opacity: 0.7, width: 0 }}
                animate={{ opacity: 0.5, width: "100%", zIndex: 1 }}
              />
            </>
          )}
        </AnimatePresence>
      </InlineButtonContainer>
    );
  }

  return (
    <InlineButtonContainer
      onMouseLeave={() => setShow(false)}
      onMouseEnter={() => setShow(true)}
    >
      <InlineButton color={color} size={size}>
        {children}
      </InlineButton>
      <AnimatePresence>
        {show && (
          <>
            <InlineButtonLine
              exit={{ width: 0 }}
              whileTap={{ scale: 1.2 }}
              initial={{ opacity: 0.7, width: 0 }}
              animate={{ opacity: 0.5, width: "100%", zIndex: 1 }}
            />
          </>
        )}
      </AnimatePresence>
    </InlineButtonContainer>
  );
};

export default TagButton;
