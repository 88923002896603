import styled from "styled-components";

export const Container = styled.form`
  display: flex;
  align-items: center;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  padding-bottom: 2rem;
  .css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    background-color: white;
  }
  .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
    border-radius: 10px !important;
    @media (max-width: 991px) {
      border-radius: 10px !important;
    }
  }
  @media (max-width: 700px) {
    height: 100%;
    display: flex;
  }
  h1 {
    font-size: 3rem;
  }
`;
export const StyledInput = styled.input`
  border: none;
  width: 100%;
  outline: none;
  font-weight: 600;
  background-color: white;
  padding: 2rem 2rem;
  opacity: 0.8;
  color: ${({ theme }) => theme.palette.grey.main};
  border-radius: 100px;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(2px);

  &::placeholder {
    opacity: 0.45;
  }
`;
export const SaveButtonWrapper = styled.div`
  button {
    width: 200px;
  }
`;
export const PerfilInfos = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.3rem;
`;

export const InfoTexts = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  h1 {
    font-size: 2rem;
  }
`;

export const FormContent = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 15px;
  width: 95%;
`;

export const DateInputs = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 20px;
`;

export const ErrorAndInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const PeriodContent = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

export const BudgetWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 2px;
`;

export const SearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;
  align-items: center;
  max-width: 450px;
  svg {
    margin-right: -10px;
    width: 20px;
  }
`;

export const SeachField = styled.input`
  width: 100%;
  border: none;
  border-bottom: 1px solid black;
  outline: none;
  background-color: transparent;
  padding: 10px;
`;

export const StyledSelect = styled.select`
  padding: 1rem 2rem;
  border-radius: 30px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 400px;
  color: rgba(0, 0, 0, 0.5);
`;

export const TableContainer = styled.div`
  height: 100%;
  width: 100%;
  background: white;
  border: "none";
  border-radius: 10;
  .MuiDataGrid-columnHeaderTitle {
    font-weight: 600;
  }
`;
export const DeleteButton = styled.button`
  display: flex;
  align-items: center;
  gap: 0.8rem;
  color: ${({ theme }) => theme.palette.grey._100};
  background: none;
  border: none;
  box-shadow: none;
  transition: 0.5s;
  svg {
    width: 18px;
    transition: 0.5s;

    path {
      fill: ${({ theme }) => theme.palette.grey._100};
      transition: 0.5s;
    }
  }
  :hover {
    color: ${({ theme }) => theme.palette.orange._100};
    svg {
      width: 20px;

      path {
        fill: ${({ theme }) => theme.palette.orange._100};
      }
    }
  }
`;

export const TextAreaStyled = styled.textarea`
  width: 600px;
  outline: none;
  height: 200px;
  resize: none;
  border-radius: 20px;
  padding: 1.5rem;
  font-family: ${({ theme }) => theme.font.familly};
`;
export const MoreItemWrapper = styled.div<{
  small?: boolean;
  hoverColor?: string;
}>`
  display: flex;
  align-items: center;
  width: fit-content;
  gap: 0.5rem;
  span {
    transition: 0.5s;
    font-size: ${({ small }) => (small ? "0.8rem" : "1.2rem")};
  }
  button {
    padding: ${({ small }) => (small ? "0.3rem" : "0.6rem")};
  }
  button > h1 {
    font-size: 0.5rem;
  }
  svg {
    width: ${({ small }) => (small ? "18px" : "25px")};
    transition: 0.5s;
  }
  path {
    transition: 0.5s;
  }

  &:hover {
    svg {
      width: 25px;
      rotate: 90deg;
    }
    span {
      color: ${({ hoverColor }) => (hoverColor ? hoverColor : "#c74c39")};
    }
  }
`;
export const AddButtonWrapper = styled.div`
  display: flex;  
  align-items: center;
  margin-top: 3%;
`;
export const ButtonWrapper = styled.div`
  button {
    width: 149px;
    height: 149px;
    border-radius: 10px;
  }
`;

export const InputLabel = styled.p`
  padding: 0px;
  margin: 0px;
  font-size: 20px
`;

export const DoubleItemsWrapper = styled.div<{
  gap?: string;
}>`
  display: flex;
  align-items: center;
  align-self: flex-end;
  gap: ${({ gap }) => (gap ? gap : "2rem")};
  p {
    display: none;
  }
`;

export const DateContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  margin-right: 200px;
  label {
    text-align: center;
  }
  
`;
export const DownloadWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
  button {
    padding: 0.3rem 1rem;
    border-radius: 5px;
    font-size: 0.5rem;
  }
`;
export const Section = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  max-width: 800px;
`;
export const SwitchWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-top: .5rem;
  flex-direction: column;
  margin-left: 150px;
`;
export const LastButtonContainer = styled.div`
  button {
    border-radius: 0.3rem 1rem;
    font-weight: 400;
    font-size: 18px;
  }
`;
