import { url, treatError, defaultHeaders } from "../../api";

export const registerPasswordRequest = async (email: string|undefined) => {
  const df = defaultHeaders();
  const headers = { ...df, mode: "no-cors" };
  return fetch(url("users/recoveryPassword"), {
    headers,
    method: "POST",
    body: JSON.stringify({'email':email}),
  }).then((r) => {
    if (r.ok) return JSON.stringify(r);
    throw new Error(treatError(r));
  });
};

export default registerPasswordRequest;
