import { url, treatError, defaultHeaders } from "../../api";

export const remove = async (id: number | undefined | React.Key) => {
  const df = defaultHeaders();
  const headers = { ...df, mode: "no-cors" };
  return fetch(url(`users/${id}`), {
    headers,
    method: "DELETE",
  }).then((r) => {
    if (r.ok) return r;
    throw new Error(treatError(r));
  });
};

export default remove;
