import React from "react";
import { TagButton } from "../Buttons";
import { Content, Container, TopContent, BottomContainer } from "./styles";
import { useUser } from "../../hooks/useUser";
import { logoIMG } from "../../assets/img";
import { ExpansiveItem, SimpleItem } from "./Items";

import {
  ClientesSVG,
  HomeSVG,
  ProfileSVG,
  TravelSVG,
} from "assets/svg/components";
import { useNavigate } from "react-router-dom";

const Menu = () => {
  const navigate = useNavigate();
  const { logout } = useUser();
  const handleLogout = () => {
    const isLoggedOut = logout();
    if (isLoggedOut) {
      navigate("/login");
    }
  };
  return (
    <Container>
      <TopContent>
        <img src={logoIMG} alt="logo da voya haus" />
      </TopContent>

      <Content>
        <SimpleItem path="/" text="Home" icon={<HomeSVG />} />
        <SimpleItem path="/perfil" text="Perfil" icon={<ProfileSVG />} />
        <ExpansiveItem
          path="/clientes/lista"
          icon={<ClientesSVG />}
          text="Clientes"
          expanded={[
            {
              label: "Cadastrar cliente",
              path: "/clientes/cadastrar",
            },
          ]}
        />
        <ExpansiveItem
          path="/viagens/lista"
          icon={<TravelSVG />}
          text="Viagens"
          expanded={[
            {
              label: "Cadastrar viagem",
              path: "/viagens/cadastrar"
            },
          ]}
        />
      </Content>

      <BottomContainer>
        <TagButton onClick={handleLogout}>Sair</TagButton>
      </BottomContainer>
    </Container>
  );
};

export default Menu;
