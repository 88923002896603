import { SignUpData } from "types/data/Admin";
import { url, treatError, defaultHeaders } from "../../api";

export const update = async (
  admin: Partial<SignUpData>
): Promise<SignUpData> => {
  const df = defaultHeaders();
  const headers = { ...df, mode: "no-cors" };
  return fetch(url(`admin/edit/${admin.id}`), {
    headers: { ...headers, mode: "no-cors" },
    method: "PATCH",
    body: JSON.stringify(admin),
  }).then((r) => {
    if (r.ok) return r.json();
    throw new Error(treatError(r));
  });
};

export default update;
