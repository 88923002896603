import styled from "styled-components";
export const TipsSection = styled.div`
  
 display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 90%;
  margin-right: 3rem;

  .quill {
    width: 100%;
    height: 400px;
  }
  .ql-editor {
    background: white;
  }
`;

export const SaveButtonWrapper = styled.div`
  button {
    width: 200px;
  }
`;
export const SectionTitle = styled.h1`
  font-size: 22px;
  font-weight: 500;
`;
export const SwitchWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin-left: 150px;
`;
