import styled from "styled-components";
import { motion } from "framer-motion";
import { backgroundLine } from "assets/img";

export const Container = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FF8D6B;
  background-image: url(${backgroundLine});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
`;


export const ButtonContainer = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  button{
    width: 100%;
    border-radius: 10px;
    padding: 0 3rem;
  }
  margin-top: 5px;
`;

export const PasswordRulesContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const PasswordRulesText = styled.p`
  margin: 0;
  font-size: .80em;
`
export const InputContainer = styled.div`
  margin: 0;
  padding: 0;
  width: 100%;  
  margin-top: -5px;
`

export const FormBox = styled(motion.form)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 70vh;
  background-color: #FEF6E9;
  max-height: 1000px;
  padding: 2rem 1.5em;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 200px;
  margin: 10%;
`
