import React from "react";

export const SearchSVG = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_41_271)">
        <path
          d="M18.8319 16.9746C20.4807 14.8532 21.2589 12.1832 21.008 9.50822C20.7571 6.83324 19.496 4.35447 17.4815 2.57673C15.467 0.798988 12.8506 -0.144036 10.1652 -0.0602942C7.47979 0.0234472 4.92726 1.12766 3.02746 3.02746C1.12766 4.92726 0.0234472 7.47979 -0.0602942 10.1652C-0.144036 12.8506 0.798988 15.467 2.57673 17.4815C4.35447 19.496 6.83323 20.7571 9.50822 21.008C12.1832 21.2589 14.8532 20.4807 16.9746 18.8319L22.7578 24.6151C22.8789 24.7423 23.0242 24.8439 23.1852 24.9141C23.3461 24.9842 23.5195 25.0215 23.6951 25.0236C23.8706 25.0257 24.0449 24.9927 24.2075 24.9265C24.3701 24.8603 24.5179 24.7623 24.642 24.6381C24.7662 24.5139 24.8643 24.3662 24.9305 24.2036C24.9967 24.0409 25.0297 23.8667 25.0275 23.6911C25.0254 23.5155 24.9882 23.3422 24.918 23.1812C24.8479 23.0202 24.7462 22.8749 24.6191 22.7538L18.8319 16.9746ZM10.5262 18.4214C8.9647 18.4214 7.43826 17.9583 6.1399 17.0908C4.84155 16.2233 3.82961 14.9902 3.23205 13.5476C2.63448 12.1049 2.47813 10.5175 2.78277 8.98595C3.0874 7.45444 3.83934 6.04766 4.9435 4.9435C6.04766 3.83934 7.45444 3.0874 8.98595 2.78277C10.5175 2.47813 12.1049 2.63448 13.5476 3.23205C14.9902 3.82961 16.2233 4.84155 17.0908 6.1399C17.9583 7.43826 18.4214 8.9647 18.4214 10.5262C18.4214 12.6201 17.5896 14.6283 16.1089 16.1089C14.6283 17.5896 12.6201 18.4214 10.5262 18.4214Z"
          fill="#383838"
        />
        <path
          d="M5.26427 11.8427C5.43707 11.8427 5.60817 11.8087 5.76778 11.7425C5.9274 11.6763 6.0724 11.5793 6.19449 11.457C6.31659 11.3347 6.41337 11.1896 6.47932 11.0299C6.54526 10.8702 6.57907 10.699 6.57882 10.5262C6.57882 9.47926 6.99472 8.47517 7.73503 7.73486C8.47535 6.99455 9.47943 6.57864 10.5264 6.57864C10.7034 6.5855 10.88 6.55655 11.0456 6.49353C11.2112 6.43051 11.3624 6.33471 11.4901 6.21188C11.6178 6.08904 11.7193 5.9417 11.7887 5.77867C11.8581 5.61564 11.8939 5.44029 11.8939 5.26311C11.8939 5.08593 11.8581 4.91058 11.7887 4.74755C11.7193 4.58452 11.6178 4.43718 11.4901 4.31434C11.3624 4.19151 11.2112 4.09571 11.0456 4.03269C10.88 3.96967 10.7034 3.94072 10.5264 3.94758C8.78227 3.94967 7.11018 4.64345 5.8769 5.87673C4.64362 7.11001 3.94984 8.7821 3.94776 10.5262C3.9475 10.6992 3.98137 10.8705 4.04744 11.0303C4.11351 11.1902 4.21047 11.3354 4.33277 11.4577C4.45508 11.58 4.60031 11.677 4.76016 11.743C4.92 11.8091 5.09131 11.843 5.26427 11.8427Z"
          fill="#383838"
        />
      </g>
      <defs>
        <clipPath id="clip0_41_271">
          <rect width="25" height="25" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
