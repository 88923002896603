import { motion } from "framer-motion";
import React from "react";
import { Outlet } from "react-router-dom";
import styled from "styled-components";
import Menu from "../components/Menu";

const Container = styled(motion.div)`
  height: -webkit-fill-available;

  gap: 30px;
  padding: 30px;

  display: flex;
  align-items: center;
  flex-direction: column;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;

  @media (max-width: 700px) {
    height: 100%;
    display: flex;
  }
`;

const Content = styled(motion.div)`
  width: 100%;
  height: 100%;
`;

const MenuLayout = () => {
  return (
    <>
      <Container>
        <Menu />
        <Content
          initial={{
            opacity: 0,
            translateY: 10,
          }}
          animate={{
            opacity: 1,
            translateY: 0,
          }}
        >
          <Outlet />
        </Content>
      </Container>
    </>
  );
};

export default MenuLayout;
