import { regexLowercase, regexSpecialChar, regexUppercase } from 'utils/regex';
import * as yup from 'yup'

export const schema = yup.object().shape({
  firstPassword: yup
    .string()
    .min(8, "A senha precisa ter pelo menos 8 caracteres")
    .max(32, "A senha não pode ter mais de 32 caracteres")
    .matches(
      regexLowercase,
      "A senha deve conter pelo menos uma letra minúscula (a)"
    )
    .matches(
      regexUppercase,
      "A senha deve conter pelo menos uma letra maiúscula (A)"
    )
    .matches(
      regexSpecialChar,
      "A senha deve conter pelo menos um caractere especial (&)"
    ),
  secondPassword: yup
    .string()
    .min(8, "A senha precisa ter pelo menos 8 caracteres")
    .max(32, "A senha não pode ter mais de 32 caracteres")
    .matches(
      regexLowercase,
      "A senha deve conter pelo menos uma letra minúscula (a)"
    )
    .matches(
      regexUppercase,
      "A senha deve conter pelo menos uma letra maiúscula (A)"
    )
    .matches(
      regexSpecialChar,
      "A senha deve conter pelo menos um caractere especial (&)"
    ),
});
