import styled, { css } from "styled-components";
import { motion } from "framer-motion";

export const Container = styled(motion.div)`
  display: flex;
  cursor: pointer;
  position: relative;
  padding-left: 35px;
  width: fit-content;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  left: 0;
  width: 0px;
  cursor: pointer;
  position: absolute;
  transform: scale(0.07);
`;

export const Text = styled(motion.h1)<{
  isChecked: boolean;
}>`
  user-select: none;
  transition: 0.2s;
  font-size: 1.1rem;
  opacity: 0.6;
  font-weight: 500;

  ${({ isChecked }) =>
    isChecked
      ? css`
          color: ${({ theme }) => theme.palette.green.main};
        `
      : css`
          color: ${({ theme }) => theme.palette.grey.main};
        `}
`;
