import { motion } from "framer-motion";
import styled from "styled-components";

export const ButtonContainer = styled(motion.div)``;
export const Divider = styled(motion.div)`
  height: 2px;
  background-color: ${({ theme }) => theme.palette.grey.main};
  margin: 20px 0;
  opacity: 0.4;
`;

export const FormContainer = styled(motion.form)`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 700px) {
    height: 100%;
    display: flex;
  }
`;

export const Wallpaper = styled(motion.div)`
  img {
    width: 400px;
  }
  margin-bottom: 20px;
  text-align: center;
`;
