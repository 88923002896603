import { activities } from "types/data/Activities";
import { url, treatError, defaultHeaders } from "../../api";

export const search = async (qs?: {
  [key: string]: number;
}): Promise<Array<activities>> => {
  const defaultHeadersReceive = defaultHeaders();
  const headers = { ...defaultHeadersReceive, mode: "no-cors" };
  return fetch(url(`activity/search`, qs), {
    headers,
    method: "GET",
  }).then((r) => {
    if (r.ok) return r.json();
    throw new Error(treatError(r));
  });
};

export default search;
