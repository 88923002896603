import styled from "styled-components";
import { motion } from "framer-motion";

export const Container = styled(motion.div)<{
  radius?: string;
  isShort?: Boolean;
}>`
  padding: 100px 50px;
  gap: 20px;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.palette.white.main};
  box-shadow: rgba(100, 100, 111, 0.15) 0px 7px 29px 0px;
  border-radius: ${({ radius }) => (radius ? radius : "40px")};
`;
