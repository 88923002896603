import { url, treatError } from "../../api";

export const registerPassword = async (newPassword: string|undefined,token:string) => {

 const  defaultHeaders = (): {
    "Content-Type": string;
    Authorization?: string;
  } => {
    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "http://localhost:3030",
    };
    // const token = loadLocalUser()?.token;
    if (token) return { ...headers, Authorization: `Bearer ${token}` };
    return headers;
  };
  const df = defaultHeaders();
  const headers = { ...df, mode: "no-cors" };
  return fetch(url("users/redefinePassword"), {
    headers,
    method: "POST",
    body: JSON.stringify({'newPassword':newPassword }),
  }).then((r) => {
    if (r.ok) return JSON.stringify(r);
    throw new Error(treatError(r));
  });
};

export default registerPassword;
