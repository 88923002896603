import React, { useState } from 'react';
import * as yup from 'yup';

import { IImages } from '../Images';
import { Close } from '@mui/icons-material';
import { Container, Content } from './style';
import { motion } from 'framer-motion';

interface ISingleImage {
	id: IImages['id'];
	onRemove: (id: number) => void;
	onChange: (id: number, url: string) => void;
	imageUrl: string;
}

const schema = yup.object().shape({
	url: yup
		.string()
		.required('É necessário adicionar uma URL.')
		.matches(/^https:\/\/images\.unsplash\.com\/.+/i, 'A URL precisa ser do unsplash')
});

const SingleImage = ({ id, imageUrl, onChange, onRemove }: ISingleImage) => {
	const [url, setUrl] = useState(imageUrl);
	const [error, setError] = useState('');

	const validateAndChange = async (newUrl: string) => {
		setUrl(newUrl);

		try {
			await schema.validate({ url: newUrl });
			setError('');
			setUrl(newUrl);
			onChange(id, newUrl);
		} catch (error) {
			if (error instanceof yup.ValidationError) {
				setError(error.errors[0]);
			}
		}
	};

	const validateCheck = React.useMemo(() => {
		if (url !== '') {
			if (error === '') {
				return 'OK';
			}
		}

		if (error !== '' && url !== '') {
			return 'ERROR';
		}

		return null;
	}, [url, error]);

	return (
		<Container
			exit={{ x: 10, opacity: 0 }}
			initial={{ x: 10, opacity: 0 }}
			animate={{ x: 0, opacity: 1 }}>
			<Content check={validateCheck}>
				<input
					type='text'
					value={url}
					placeholder='Insira o link do unsplash aqui'
					onChange={(e) => validateAndChange(e.target.value)}
				/>
				<motion.button
					whileTap={{ scale: 0.9 }}
					type='button'
					onClick={() => onRemove(id)}>
					<Close />
				</motion.button>
			</Content>
			{error && <p>{error}</p>}
		</Container>
	);
};

export default SingleImage;
