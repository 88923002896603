import { motion } from "framer-motion";
import styled from "styled-components";

export const ErrorContainer = styled(motion.div)`
  right: -330px;
  z-index: 999;
  position: absolute;

  transition: right 0.4s;

  @media screen and (max-width: 1200px) {
    right: 40%;
    top: 120px;
    transform: translateX(-50%);
  }
`;

export const ErrorModal = styled(motion.div)`
  background: ${({ theme }) => theme.palette.orange._200};
  border: 2px solid ${({ theme }) => theme.palette.orange.main};
  color: #fff;
  z-index: 999;
  padding: 10px 25px;
  border-radius: 10px;
  width: 250px;
  max-width: 250px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  .icon {
    color: ${({ theme }) => theme.palette.orange.main};
    position: absolute;
    right: 5px;
    top: 5px;
    z-index: 1000;
  }
`;

export const ErrorArrow = styled.div`
  position: absolute;
  content: "";
  left: -3px;
  background: ${({ theme }) => theme.palette.orange.main};
  top: 50%;
  z-index: -10;
  border-radius: 2px;
  transform: scale(2) rotate(45deg);
  width: 10px;
  height: 10px;


  @media screen and (max-width: 1200px) {
    left: 50%;
    top: 0;
  }
`;
