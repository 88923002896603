import React from "react";

export const ProfileSVG = ({width = 22, height = 23}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.0430754 22.2357C0.122842 20.8204 0.532856 19.2867 1.17542 17.9998C2.19171 15.9645 4.24608 13.9522 6.25839 13.021C6.60295 12.8616 6.95163 12.7073 7.03323 12.6782C7.17166 12.6289 7.16668 12.6146 6.95904 12.4656C6.53677 12.1626 5.71744 11.3298 5.39124 10.8721C4.43727 9.53354 3.97563 7.71484 4.1726 6.07108C4.51377 3.22393 6.58961 0.859103 9.35467 0.1676C10.2599 -0.0587755 11.8176 -0.055336 12.6716 0.174924C15.0956 0.828454 16.9168 2.63398 17.6231 5.08381C17.7661 5.57959 17.7905 5.83901 17.7898 6.8575C17.7889 8.2763 17.693 8.73089 17.1607 9.83993C16.6323 10.9407 16.0551 11.6489 15.0193 12.4673L14.8445 12.6054L15.7119 13.0311C19.3734 14.828 21.6626 18.2202 21.9487 22.2729L22 22.9999H21.0638H20.1276V22.603C20.1276 21.5897 19.7104 19.9804 19.1624 18.8797C18.2959 17.1395 16.7706 15.6058 15.0457 14.7404C12.9159 13.6718 10.4187 13.4958 8.1142 14.2518C5.51515 15.1045 3.3516 17.2258 2.39536 19.8591C2.13856 20.5663 1.8775 21.7951 1.87607 22.3033C1.87405 23.0237 1.90761 22.9999 0.892796 22.9999H0L0.0430754 22.2357ZM11.5115 11.8872C13.885 11.5881 15.6476 9.83913 15.9775 7.45569C16.2912 5.18905 14.7595 2.79768 12.5329 2.07786C9.42506 1.07314 6.21155 3.27299 5.97976 6.56392C5.79609 9.17148 7.79916 11.5817 10.4045 11.8881C10.6721 11.9196 10.8919 11.9497 10.893 11.9551C10.8941 11.9605 11.1724 11.93 11.5115 11.8872Z"
        fill="#F1896E"
      />
    </svg>
  );
};

