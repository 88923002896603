import { Button } from 'components/Buttons';
import { Input } from 'components/Inputs';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { AddButtonWrapper, ErrorAndInputContainer } from '../../style';
import { CheckListWrapper } from './components/ChecklistWrapper';
import { ChecklistSection, InputLabel, SectionTitle, SwitchWrapper } from './style';
import { Props } from './types';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQueries } from 'react-query';
import { create as createHeader } from 'api/requests/checklist/header/create';
import { toast } from 'react-toastify';
import { checklist_header } from 'types/data/ChecklistHeader';
import { list } from 'api/requests/checklist/header/search';
import { queryClient } from 'api/queryClient';
import { remove } from 'api/requests/checklist/header/remove';
import Switch from 'components/Inputs/Switch';
import { useSelectedIdContext } from 'context/SelectedIdContext';

type FormInputs = {
	checklistTitle: string;
	checklistItem: string;
};

const schema = yup.object().shape({
	checklistTitle: yup.string().required('digite o titulo da checklist'),
	checklistItem: yup.string().required('digite o titulo do item')
});

export default function Checklist({ handleMoveItemDown, handleMoveItemUp, disable }: Props) {
	const [isVisible, setIsVisible] = useState<boolean>(false);
	const {
		reset,
		trigger,
		register,
		setValue,
		getValues,
		formState: { errors }
	} = useForm<FormInputs>({
		resolver: yupResolver(schema)
	});

	const { selectedId } = useSelectedIdContext();
	const [travelId] = useState(Number(selectedId));
	const [checklist_query] = useQueries([
		{
			queryKey: ['checklist-headers', 1],
			queryFn: () => list(travelId),
			onSuccess: (data: checklist_header[]) => { },

		}
	]);

	const checklistCreateMutation = useMutation({
		mutationFn: (header: checklist_header) => createHeader(header),

		onSuccess: (data) => {
			toast.warning('item criado');
			queryClient.invalidateQueries("checklist-items");
			queryClient.invalidateQueries("checklist-headers");
		},

		onError: (error) => {
			toast.error(`Algo deu errado: ${error}`);
		}
	});

	const checklistDeleteMutation = useMutation({
		mutationFn: (id: number) => remove(id),

		onSuccess: () => {
			toast.warning('item deletado');
			queryClient.invalidateQueries("checklist-items");
			queryClient.invalidateQueries("checklist-headers");
		},
		onError: (error: any) => {
			toast.error(`Algo deu errado: ${error}`);
		}
	});

	const handleAddChecklist = async () => {
		try {
			const onTrigger = await trigger('checklistTitle');

			if (onTrigger) {
				queryClient.refetchQueries("checklist-headers");

				if (travelId) {
					const checklistTitle: string = getValues('checklistTitle');
					const newCheckListData: checklist_header = {
						title: checklistTitle,
						travel_id: travelId,
						isvisible: isVisible,
					};

					checklistCreateMutation.mutate(newCheckListData);
					setValue('checklistTitle', '');
				} else {
					reset();
					toast.error('É necessário que seja criada uma viagem ');
				}
			}
		} catch (e) { }
	};

	const removeChecklist = (id: number) => {
		checklistDeleteMutation.mutate(id);
	};

	return (
		<ChecklistSection>
			<SectionTitle>Checklist:</SectionTitle>
			<ErrorAndInputContainer>
				<Input
					label="Título da Checklist"
					placeholder='Titulo da Checklist'
					register={{ ...register('checklistTitle', { required: true }) }}
					error={{ message: errors.checklistTitle?.message, showMessage: true }}
					color={disable ? '' : 'white'}
					disable={disable}
					size='400px'
				/>
				<SwitchWrapper>
					<InputLabel>Visibilidade: </InputLabel>
					<Switch
						checked={isVisible}
						handleChange={setIsVisible}
						options={['Invisível', 'Visível']}
					/>
				</SwitchWrapper>
				<AddButtonWrapper onClick={() => handleAddChecklist()}>
					<Button buttonType='button'>Add Checklist</Button>
				</AddButtonWrapper>
			</ErrorAndInputContainer>


			<div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
				{checklist_query.data?.map((item, index) => {
					const aleatoryid = Math.random();
					return (
						<CheckListWrapper
							travelId={travelId}
							checklistItems={item.items}
							checklistHeaderId={item.id}
							handleMoveItemDown={handleMoveItemDown}
							handleMoveItemUp={handleMoveItemUp}
							removeChecklist={removeChecklist}
							id={aleatoryid}
							props={item}
							key={aleatoryid}
						/>
					);
				})}
			</div>
		</ChecklistSection>
	);
}
