import { checklist_header } from "types/data/ChecklistHeader";
import { url, treatError, defaultHeaders } from "../../../api";

export const list = async (
  travelId: number | undefined
): Promise<Array<checklist_header>> => {
  const defaultHeadersReceive = defaultHeaders();
  const headers = { ...defaultHeadersReceive, mode: "no-cors" };
  return fetch(url(`checklist/draft/travel/${travelId}/header`), {
    headers: headers,
    method: "GET",
  }).then((r) => {
    if (r.ok) return r.json();
    throw new Error(treatError(r));
  });
};
