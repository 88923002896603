import styled from "styled-components";

export const ChecklistSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 90%;
  margin-right: 3rem;
`;
export const InputLabel = styled.p`
  padding: 0px;
  margin: 0px;
  font-size: 20px
`;
export const ChecklistContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding: 1.3rem;
  border-radius: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  h1 {
    color: ${({ theme }) => theme.palette.grey._300};
    font-weight: 600;
  }
`;

export const ChecklistHeader = styled.div`
  background-color: #f2f2f2;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 1rem 0rem;
  margin-bottom: 1rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  h1 {
    font-weight: bold;
    font-family: ${({ theme }) => theme.font.familly};
    color: ${({ theme }) => theme.palette.orange.main};
    font-size: 38px;
  }
`;
export const AddListItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 2rem;
  margin-top: 1rem;
  input {
    background-color: ${({ theme }) => theme.palette.white.main};
    outline: none;
    border: 3px solid rgba(50, 50, 93, 0.25);
    border-radius: 5px;
    padding: 0.4rem;
  }
  button {
    font-size: 0.4rem;
    padding: 2px 10px;
    border-radius: 5px;
  }
  button > h1 {
    font-size: 0.8rem;
  }
`;
export const ListItemWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  gap: 2rem;
  .dfIxuU {
    display: none;
  }
`;
export const SectionTitle = styled.h1`
  font-size: 22px;
  font-weight: 500;
`;
export const SwitchWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: .5rem;
  flex-direction: column;
  margin-left: 150px;
`;
export const EditButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;
