import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";

export const columns: GridColDef[] = [
  { field: "title", headerName: "Titulo", width: 280 },
  { field: "destination", headerName: "Destino", width: 350 },
  { field: "users", headerName: "Nº de Participantes", width: 200, align: "center" },
  { field: "startdate", headerName: "Inicio", width: 150 },
  { field: "enddate", headerName: "Fim", width: 150 },
  {
    field: "status",
    headerName: "Status",
    width: 250,
    renderCell: (params: GridRenderCellParams<string>) => {
      const paramsValue = params.value;
      const handleFieldColor = () => {
        switch (paramsValue) {
          case "Realizada":
            return "#075D68";
            break;

          case "Em progresso":
            return "#F1896E";
            break;

          case "Agendada":
            return "#646464";
            break;
        }
      };
      return (
        <strong>
          <>
            <span
              style={{ color: handleFieldColor() }}
              tabIndex={params.hasFocus ? 0 : -1}
            >
              {params.value}
            </span>
          </>
        </strong>
      );
    },
  },
];