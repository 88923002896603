import { FormControlLabel } from "@mui/material";
import React, { Dispatch, SetStateAction } from "react";
import { Container, SpanOption } from "./style";
import Switch from "@mui/material/Switch";
type Props = {
  checked?: boolean;
  handleChange: Dispatch<SetStateAction<boolean>>;
  options: string[];
  disabled?: boolean;
};
function SwitchInput({ checked = false, options, handleChange, disabled = false }: Props) {
  const setChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(event.target.checked);
  };
  const optionSelected = (name: "option1" | "option2") => {
    if (name === "option1") {
      const value = checked ? false : true;
      return value;
    }
    if (name === "option2") {
      const value = checked ? true : false;
      return value;
    }
  };
  const option1IsSelected = optionSelected("option1");
  const option2IsSelected = optionSelected("option2");
  return (
    <>
      <Container>
        {options[0] && (
          <SpanOption
            selected={option1IsSelected}
            color={option1IsSelected ? "white" : "#0B8B9C"}
          >
            {options[0]}
          </SpanOption>
        )}
        <Switch
          disabled={disabled}
          checked={checked}
          onChange={setChangeValue}
          inputProps={{ "aria-label": "controlled" }}
        />
        {options[1] && (
          <SpanOption
            selected={option2IsSelected}
            color={option2IsSelected ? "white" : "#0B8B9C"}
          >
            {options[1]}
          </SpanOption>
        )}
      </Container>
    </>
  );
}

export default SwitchInput;
