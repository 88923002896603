import { Button } from "components/Buttons";
import React from "react";
import { ButtonsWrapper, Container } from "./style";
import { AnimatePresence } from "framer-motion";
type Props = {
  message: string;
  handleConfirm: () => void;
  handleCancel: () => void;
  open: boolean;
};
const ConfirmModal = ({
  message,
  open,
  handleCancel,
  handleConfirm,
}: Props) => {
  return (
    <AnimatePresence>
      {open && (
        <Container
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{
            opacity: 1,
            scale: 1,
            transition: { type: "spring", stiffness: 130 },
          }}
          exit={{ opacity: 0, scale: 0 }}
        >
          <span>{message}</span>
          <ButtonsWrapper>
            <div onClick={handleCancel}>
              <Button>Cancelar</Button>
            </div>
            <div onClick={handleConfirm}>
              <Button color="green">Confirmar</Button>
            </div>
          </ButtonsWrapper>
        </Container>
      )}
    </AnimatePresence>
  );
};
export default ConfirmModal;
