import styled from "styled-components";
import { motion } from "framer-motion";
export const Container = styled(motion.div)`
  background-color: ${({ theme }) => theme.palette.white.main};
  width: 500px;
  max-height: 500px;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;
export const Header = styled(motion.div)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  h1 {
    font-family: ${({ theme }) => theme.font.familly};
    font-weight: bold;
    color: ${({ theme }) => theme.palette.orange.main};
    font-size: 1.3rem;
  }
`;
export const ItemsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  max-height: 450px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 1rem 0;
`;
export const RatingItemContainer = styled(motion.div)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${({ theme }) => theme.palette.grey._200};
  gap: 20px;
  padding: 0.5rem 0rem;
  border-radius: 15px;
`;
export const RatingItemInfos = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  span {
    color: white;
    font-family: ${({ theme }) => theme.font.familly};
    font-weight: bold;
    font-size: 1.3rem;
  }
`;
export const RatingItemComment = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    color: white;
    font-size: 1rem;
  }
`;
export const StarWrapper = styled.div``;
export const CloseButtonWrapper = styled(motion.div)`
  max-width: 200px;
  width: 150px;
  position: relative;
  bottom: 0;
  top: 50%;
`;
