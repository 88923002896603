import { motion } from "framer-motion";
import styled from "styled-components";


export const p = styled.p`
font-family: ${({ theme }) => theme.font.familly};
`;

export const Container = styled.div`
  padding-left: 20px;
`;

export const ScrollBody = styled.body`
    overflow-y: scroll
`