import React, { useState } from "react";
import {
  Section,
  Container,
  DateInputs,
  ContentForm,
  TopContainer,
  ButtonsSection,
  PhoneContainer,
  BirthDateContent,
} from "./styles";

import { Input } from "components/Inputs";
import { useForm } from "react-hook-form";
import { Button } from "components/Buttons";
import { MaskedInput } from "components/Inputs/Masks";
import { yupResolver } from "@hookform/resolvers/yup";

import { formatedDateOfRequest, formatPhoneToRequest } from "utils/formatters";
import { useMutation } from "react-query";
import create from "api/requests/user/create";
import { User } from "types/data/User";
import { toast } from "react-toastify";
import { queryClient } from "api/queryClient";
import { InputsForm } from "../types";
import { schema } from "./schemas";
import { InputLabel } from "components/Inputs/styles";
import { useNavigate } from "react-router-dom";
import { NEW_ITEM, useSelectedIdContext } from "context/SelectedIdContext";
import removeEmptyStringAttributes from "utils/removeEmptyStringAttributes";

const Register = () => {
  const [cleanFields, setCleanFields] = useState(false);
  const navigate = useNavigate();

  const {
    register,
    getValues,
    handleSubmit,
    reset,
    setValue,
    formState,
    clearErrors,
    formState: { errors, isSubmitSuccessful },
  } = useForm<InputsForm>({
    resolver: yupResolver(schema),
  });
  const handleCleanFields = () => {
    setValue("fullName", "");
    setValue("email", "");
    setValue("firstPhone", "");
    setValue("secondPhone", "");

    setValue("day", "");
    setValue("month", "");
    setValue("year", "");

    setValue("emergencyContactEmail", "");
    setValue("emergencyContactName", "");
    setValue("emergencyContactPhone", "");

    setCleanFields(true);
  };

  const { setSelectedId } = useSelectedIdContext();

  const { isLoading, mutate, isSuccess } = useMutation({
    mutationFn: (userData: User) => {
      const response = create(userData);

      return response;
    },
    onSuccess: (data: any) => {
      toast.success("Usuário Criado");
      handleCleanFields();
      clearErrors();
      queryClient.invalidateQueries("clients-list");
      setSelectedId(NEW_ITEM);
      navigate(-1);
    },
    onError: (error) => {
      toast.error(`Algo deu errado e não foi possível criar o usuário: ${error}`);
    },
  });
  const onSubmit = (data: InputsForm) => {
    const dateString = `${data.year}${data.month}${data.day}`;
    const formatedData = formatedDateOfRequest(dateString);

    const validateSecondPhone = () => {
      if (data?.secondPhone) {
        const formatedUser: User = {
          birthdate: formatedData,
          email: data.email,
          emergencyname: data.emergencyContactName,
          emergencyphone: data.emergencyContactPhone,
          emergencyemail: data.emergencyContactEmail,
          fullname: data.fullName,
          phone1: data.firstPhone,
          phone2: data?.secondPhone,
        };
        return formatedUser;
      } else {
        const formatedUser: User = {
          birthdate: formatedData,
          email: data.email,
          emergencyname: data.emergencyContactName,
          emergencyphone: data.emergencyContactPhone,
          emergencyemail: data.emergencyContactEmail,
          fullname: data.fullName,
          phone1: data.firstPhone,
        };
        return formatedUser;
      }
    };

    const userValidated: any = removeEmptyStringAttributes(validateSecondPhone());

    mutate(userValidated);
  };

  return (
    <Container>
      <h1>Cadastrar Cliente</h1>
      <ContentForm onSubmit={handleSubmit(onSubmit)}>

        <TopContainer>
          <Input
            error={{
              message: errors.fullName?.message,
              showMessage: true,
            }}
            register={{ ...register("fullName", { required: true }) }}
            name="fullName"
            label="Nome completo"
            placeholder="Nome Completo"
            color="white"
          />

          <Input
            register={{ ...register("email", { required: true }) }}
            error={{
              message: errors.email?.message,
              showMessage: true,
            }}
            name="email"
            label="Email"
            inputType="email"
            placeholder="Email"
            color="white"
          />
          <BirthDateContent>
            <InputLabel>Data de nascimento:</InputLabel>
            <DateInputs>
              <MaskedInput
                cleanFields={cleanFields}
                setCleanFields={setCleanFields}
                reset={reset}
                register={{ ...register("day", { required: true }) }}
                error={{
                  message: errors.day?.message,
                  showMessage: true,
                }}
                option="day"
                name="day"
                id="day"
                color="white"
                placeholder="DD"
                size="50px"
              />

              <MaskedInput
                cleanFields={cleanFields}
                setCleanFields={setCleanFields}
                reset={reset}
                register={{ ...register("month", { required: true }) }}
                error={{
                  message: errors.month?.message,
                  showMessage: true,
                }}
                option="month"
                id="month"
                color="white"
                placeholder="MM"
                size="50px"
              />

              <MaskedInput
                cleanFields={cleanFields}
                setCleanFields={setCleanFields}
                reset={reset}
                register={{ ...register("year", { required: true }) }}
                error={{
                  message: errors.year?.message,
                  showMessage: true,
                }}
                option="year"
                id="year"
                color="white"
                placeholder="AAAA"
                size="120px"
              />
            </DateInputs>
          </BirthDateContent>
        </TopContainer>

        <Section>
          <PhoneContainer>
            <MaskedInput
              cleanFields={cleanFields}
              setCleanFields={setCleanFields}
              reset={reset}
              register={{ ...register("firstPhone", { required: true }) }}
              error={{
                message: errors.firstPhone?.message,
                showMessage: true,
              }}
              id="firstPhone"
              color="white"
              option="phone"
              label="Telefone 1"
              placeholder="Telefone 1"
            />

            <MaskedInput
              cleanFields={cleanFields}
              setCleanFields={setCleanFields}
              reset={reset}
              register={{ ...register("secondPhone") }}
              id="secondPhone"
              name="secondPhone"
              color="white"
              option="phone"
              label="Telefone 2"
              placeholder="Telefone 2"
            />
          </PhoneContainer>
        </Section>
        <h1>Contatos de emergência</h1>
        <Section>
          <Input
            register={{
              ...register("emergencyContactName", { required: true }),
            }}
            error={{
              message: errors.emergencyContactName?.message,
              showMessage: true,
            }}
            name="emergencyContactName"
            label="Nome do contato"
            placeholder="Nome do contato"
            color="white"
          />

          <MaskedInput
            cleanFields={cleanFields}
            setCleanFields={setCleanFields}
            reset={reset}
            register={{
              ...register("emergencyContactPhone", { required: true }),
            }}
            error={{
              message: errors.emergencyContactPhone?.message,
              showMessage: true,
            }}
            id="emergencyContactPhone"
            color="white"
            option="phone"
            label="Telefone do contato"
            placeholder="Telefone do contato"
          />
          <Input
            register={{
              ...register("emergencyContactEmail", { required: true }),
            }}
            error={{
              message: errors.email?.message,
              showMessage: true,
            }}
            name="emergencyContactEmail"
            label="Email do Contato"
            inputType="email"
            placeholder="Email Do Contato"
            color="white"
          />
        </Section>
        <ButtonsSection>
          <Button buttonType="submit">Pronto</Button>
        </ButtonsSection>
      </ContentForm>
    </Container>
  );
};

export default Register;
