import { ReceiveUser } from "types/data/User";
import { url, treatError, defaultHeaders } from "../../api";

export const findOne = async (
  qs: number | string | undefined
): Promise<Array<ReceiveUser>> => {
  const df = defaultHeaders();
  const headers = { ...df, mode: "no-cors" };
  return fetch(url(`users/find/${qs}`), {
    headers,
    method: "GET",
  }).then((r) => {
    if (r.ok) return r.json();
    throw new Error(treatError(r));
  });
};

export default findOne;
