import { activities } from "types/data/Activities";
import { url, treatError, defaultHeaders } from "../../api";

export const create = async (activity: activities): Promise<activities> => {
  const defaultHeadersReceive = defaultHeaders();
  const headers = { ...defaultHeadersReceive, mode: "no-cors" };
  return fetch(url(`activity`), {
    headers,
    method: "POST",
    body: JSON.stringify(activity),
  }).then((r) => {
    if (r.ok) return r.json();
    throw new Error(treatError(r));
  });
};

export default create;
