import { motion } from "framer-motion";
import styled, { keyframes } from "styled-components";

const waveLetter = keyframes`
  100% {
    transform: translateY(-5px);
  }
`;

export const Container = styled(motion.div)`
  top: 50%;
  left: 50%;
  color: white;
  position: fixed;
  z-index: 999999;
  transform: translate(-50%, -50%);

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  
  background-color: ${({ theme }) => theme.palette.white.main};
  padding: 35px 35px 20px 35px;
  border-radius: 15px;
  width: 200px;
  border: 1px solid rgba(0,0,0,0.4);

  h1 {
    color: ${({ theme }) => theme.palette.orange.main};
    font-weight: 500;
  }
`;

function addDelay(max: number) {
  let css = '';

  Array(max).fill(0).forEach((_, index) => {
    css += `
      &:nth-child(${index + 1}) {
        animation-delay: ${index * 0.1}s;
      }
    `;
  })

  return css;
}
export const Letter = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;

  h1 {
    transition: 0.3s;
    animation: ${waveLetter} 1s ease infinite alternate;
    ${addDelay(10)}
  }
`;

export const Backdrop = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  height: 100%;
  position: fixed;
  background: rgba(0,0,0,0.5);
  backdrop-filter: blur(1px);
`;
