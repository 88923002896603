import React, { createContext } from "react";

interface IProvider {
  children: React.ReactNode;
}

export interface iLoadingContext {
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const LoadingContext = createContext<iLoadingContext>({} as iLoadingContext);

export const LoadingProvider = ({ children }: IProvider) => {
  const [loading, setLoading] = React.useState(false);

  const value = {
    loading,
    setLoading
  };

  return (
    <LoadingContext.Provider value={value}>
      {children}
    </LoadingContext.Provider>
  );
}

export const useLoading = () => {
  const loading = React.useContext(LoadingContext);

  if (!loading) {
    throw new Error("useLoading must be used within a LoadingProvider");
  }

  return loading;
}
