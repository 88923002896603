import { createGlobalStyle } from "styled-components";

const Global = createGlobalStyle`
  body, html, #root{
    margin: 0;
    padding: 0;
    height: 100%;
    overflow-y: hidden;
  }

  *{
    font-size: 1rem;
  }

  h1,h2,h3,p,input,span,label{
    font-smooth: always;
    font-smooth: 2em;

    user-select{
      background-color: ${({ theme }) => theme.palette.orange._100};
    }

    font-weight: normal;
    color: ${({ theme }) => theme.palette.grey.main};
    font-family: ${({ theme }) => theme.font.familly};
  }

  body{
    background-color: ${({ theme }) => theme.palette.background}
  }

`;

export default Global;
