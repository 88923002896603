import { tip } from "types/data/Tip";
import { url, treatError, defaultHeaders } from "../../api";

export const search = async (id: number): Promise<Array<tip>> => {
  const defaultHeadersReceive = defaultHeaders();
  const headers = { ...defaultHeadersReceive, mode: "no-cors" };

  return fetch(url(`travels/tips/search?travel_id=${id}`), {
    headers,
    method: "GET",
  }).then((r) => {
    if (r.ok) return r.json();
    throw new Error(treatError(r));
  });
};

export default search;
