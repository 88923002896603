import styled from "styled-components";
import { motion } from "framer-motion";

export const Container = styled(motion.div)`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 1rem 2rem;
  border-bottom: 3px solid rgba(0, 0, 0, 0.1);
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  margin-bottom: 1rem
`;
export const TitleDescriptionContainer = styled(motion.div)`
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;
  border-bottom: 3px solid rgba(0, 0, 0, 0.1);
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
`;
export const FlexContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ChecklistItemContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;`;


export const ButtonsWrapper = styled(motion.div)`
  display: flex;
  align-items: center;
  align-self: center;
`;

export const ListTitle = styled.h1<{
  done: boolean;
}>`
  text-decoration: ${({ done }) => (done ? "line-through" : "none")};
`;
export const StyledInput = styled.input`
border-radius: 20px;
padding: 1rem;
border: none;
outline: none;
background-color: ${({ theme }) => theme.palette.background};
box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
`
export const ButtonWrapper = styled.a`
button{
  transform: scale(0.8);
}
`