import { StarSVG } from "assets/svg/components/StarSVG";
import React, { useEffect, useState } from "react";
import {
  RatingItemComment,
  RatingItemContainer,
  RatingItemInfos,
  StarWrapper,
} from "./style";
import Rating from "@mui/material/Rating";
import { yellow } from "@mui/material/colors";
import Typography from "@mui/material/Typography";
import StarIcon from "@mui/icons-material/Star";
import { useMutation, useQuery } from "react-query";
import { ReceiveUser } from "types/data/User";
import { findOne } from "api/requests/user/findOne";
type IRatingListItem = {
  user_id: number | undefined;
  starRating: number | undefined;
  comment: string | undefined;
};
export const RatingListItem = ({
  user_id,
  comment,
  starRating,
}: IRatingListItem) => {
  const [clientName, setClientName] = useState<ReceiveUser>();
  const query = useMutation({
    mutationFn: (id: number | undefined) => findOne(id),

    onSuccess(data: ReceiveUser[]) {
      data.map((item) => {
        setClientName(item);
      });
    },
  });
  useEffect(() => {
    query.mutate(user_id);
  }, [user_id]);
  return (
    <RatingItemContainer
      initial={{ width: 0 }}
      animate={{ width: "100%" }}
      transition={{
        delay: 0.1,
        duration: 0.1,
        type: "spring",
        stiffness: 100,
      }}
      exit={{ opacity: 0, width: 0 }}
    >
      <RatingItemInfos>
        <StarWrapper>
          <Rating
            name="read-only"
            value={starRating}
            precision={0.5}
            emptyIcon={
              <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
            }
            size="large"
            readOnly
            sx={{
              "& .MuiRating-iconFilled": {
                color: "yellow",
                width: "30px",
              },
              "& .MuiRating-iconFocus": {
                color: "orange",
              },
              "& .MuiRating-iconHover": {
                color: "green",
              },
            }}
          />
        </StarWrapper>
        <span>{clientName?.fullname}</span>
      </RatingItemInfos>
      <RatingItemComment>
        <span>"{comment}"</span>
      </RatingItemComment>
    </RatingItemContainer>
  );
};
