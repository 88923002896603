import React, { createContext } from "react";

interface IProvider {
  children: React.ReactNode;
}

export const NEW_ITEM = 0;
export interface ISelectedIdContext {
  selectedId: React.Key | undefined;
  setSelectedId: React.Dispatch<React.SetStateAction<React.Key | undefined>>;
}

export const SelectedIdContext = createContext<ISelectedIdContext>(
  {} as ISelectedIdContext
);

export const SelectedIdContextProvider = ({ children }: IProvider) => {
  const [selectedId, setSelectedId] = React.useState<React.Key>();
  
  const value = {
    selectedId,
    setSelectedId,
  };

  return (
    <SelectedIdContext.Provider value={value}>
      {children}
    </SelectedIdContext.Provider>
  );
};

export const useSelectedIdContext = () => {
  const context = React.useContext(SelectedIdContext);

  if (!context) {
    throw new Error(
      "useProfileContext must be used within a ProfileContextProvider"
    );
  }

  return context;
};
