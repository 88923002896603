import React from 'react';
import { AnimatePresence } from 'framer-motion';
import { iError } from '../types';
import { Container } from './style';

interface iErrorMessage {
  error: iError
}

const ErrorMessage = ({ error }: iErrorMessage) => {
  return (
    <AnimatePresence>
    {error?.showMessage && (
      <Container
        exit={{ y: '10px', opacity: 0 }}
        initial={{ y: '10px', opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
      >
        {error?.message}
      </Container>
    )}
  </AnimatePresence>
  );
}

export default ErrorMessage;
