import React from "react";
import { Container } from "./styles";

interface IBox {
  children: React.ReactNode;
  isShort?: Boolean;
}

const Box = ({ children, isShort }: IBox) => {
  return (
    <Container
      initial={{
        opacity: 0,
        y: 500,
      }}
      animate={{
        y: 0,
        opacity: 1,
      }}
      transition={{
        delay: 0.3,
      }}
    >
      {children}
    </Container>
  );
};

export default Box;
