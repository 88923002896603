import styled from "styled-components";
import { motion } from "framer-motion";
export const Container = styled(motion.div)`
  height: 100%;
  transform: scale(1);
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const TagButtonContainer = styled(motion.div)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const TextContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    color: ${({ theme }) => theme.palette.grey.main};
    width: 450px;
    text-align: center;
    font-size: 1.2rem;
    font-family: ${({ theme }) => theme.font.familly};
    @media (max-width: 425px) {
      width: 296px;
    }
  }
`;

export const ButtonContainer = styled(motion.div)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
