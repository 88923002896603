import { Travel } from "types/data/Travel";
import { url, treatError, defaultHeaders } from "../../api";

export const update = async (travel: Partial<Travel>): Promise<Travel> => {
  const df = defaultHeaders();
  const headers = { ...df, mode: "no-cors" };
  return fetch(url(`travels/${travel.id}`), {
    headers,
    method: "PATCH",
    body: JSON.stringify(travel),
  }).then((r) => {
    if (r.ok) return r.json();
    throw new Error(treatError(r));
  });
};

export default update;
