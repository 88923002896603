import React from "react";

export const HomeSVG = () => {
  return (
    <svg
      width="27"
      height="26"
      viewBox="0 0 27 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.4347 11.1298L14.6734 0.453165C14.3514 0.161558 13.9323 4.57764e-05 13.4977 4.57764e-05C13.063 4.57764e-05 12.6439 0.161558 12.3219 0.453165L0.564628 11.1298C0.224567 11.4404 0.0218237 11.8732 0.000934944 12.3331C-0.0199538 12.7929 0.14272 13.2422 0.453222 13.5823C0.618058 13.7595 0.817502 13.901 1.03923 13.998C1.26096 14.0951 1.50026 14.1458 1.74235 14.1468C2.18371 14.1545 2.61111 13.9922 2.93598 13.6936L13.4957 4.08227L24.0752 13.7056C24.4171 14.0181 24.8693 14.1822 25.3323 14.1617C25.7953 14.1412 26.2312 13.9378 26.5441 13.5963C26.8569 13.2547 27.0212 12.8029 27.0007 12.3404C26.9802 11.8779 26.7766 11.4424 26.4347 11.1298ZM4.18532 14.1388V25.0182C4.18994 25.1825 4.23803 25.3425 4.32469 25.4822C4.41136 25.6218 4.53349 25.736 4.67869 25.8132C4.90543 25.9415 5.16218 26.0073 5.42272 26.004H21.5766C21.8371 26.0073 22.0939 25.9415 22.3206 25.8132C22.4653 25.7354 22.587 25.621 22.6735 25.4815C22.7601 25.342 22.8085 25.1823 22.814 25.0182V14.1388L13.4957 5.67622L4.18532 14.1388ZM9.66012 13.2803H17.3113V18.6106H9.66012V13.2803ZM2.41874 1.39522L9.58054 1.61782L2.86834 8.08503L2.41874 1.39522Z"
        fill="#F1896E"
      />
    </svg>
  );
};

