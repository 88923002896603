import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import MenuLayout from "layout/MenuLayout";
import {
  HomePage,
  LoginPage,
  ProfilePage,
  TravelHomePage,
  ClientsListPage,
  TravelRegisterPage,
  ClientsRegisterPage,
} from "pages";
import { useLoading } from "context/LoadContext";
import Loading from "components/Loading";
import ForgetPassword from "pages/ForgetPassword";
import RedefinePassword from "pages/RedefinePassword";
import Client from "pages/Clients/Client";
import useUser from "hooks/useUser";
import { useIsLoggedContext } from "context/IsLoggedContext";
import Legal from "pages/Legal/Legal";

interface IRequireAuth {
  children: React.ReactNode;
}

const RequireAuth = ({ children }: IRequireAuth) => {
  const { isLogged } = useIsLoggedContext();
  const AdminData = window.localStorage.getItem("userInfo");

  if (isLogged || AdminData) {
    return <>{children}</>;
  }

  return <Navigate to="/login" />;
};

const RouterComponent = () => {
  const { loading } = useLoading();

  return (
    <BrowserRouter>
      <Loading open={loading} />
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/esqueci-a-senha" element={<ForgetPassword />} />
        <Route path="/cadastrar-senha" element={<RedefinePassword />} />
        <Route
          path="/"
          element={
            <RequireAuth>
              <MenuLayout />
            </RequireAuth>
          }
        >
          <Route path="" element={<HomePage />} />

          <Route path="/clientes">
            <Route path="lista" element={<ClientsListPage />}></Route>
            <Route path="cliente" element={<Client />} />
            <Route path="cadastrar" element={<ClientsRegisterPage />} />
          </Route>

          <Route path="/perfil" element={<ProfilePage />} />

          <Route path="/viagens">
            <Route path="lista" element={<TravelHomePage />} />
            <Route path="cadastrar" element={<TravelRegisterPage />} />
            <Route path="viagem" element={<TravelRegisterPage />} />
          </Route>
        </Route>
        <Route path="/legal" element={<Legal />} />
      </Routes>
    </BrowserRouter>
  );
};

export default RouterComponent;
