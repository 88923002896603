import React, { useEffect, useState } from 'react';

import SingleImage from './SingleImage';

import { Container, Content, Header } from './style';
import { TagButton } from 'components/Buttons';
import { InputsForm } from '../types';
import { UseFormSetValue } from 'react-hook-form';
import { AnimatePresence } from 'framer-motion';

export interface IImages {
	id: number;
	url: string;
	_isValidate?: boolean;
}

interface ImagesProps {
	setValue: UseFormSetValue<InputsForm>;
	defaultValue?: string;
}

const Images = ({ setValue, defaultValue }: ImagesProps) => {
	const [images, setImages] = useState<IImages[]>([] as IImages[]);

	useEffect(() => {
		if (defaultValue) {
			const newImages = defaultValue?.split(',').map((img, index) => ({
				id: index,
				url: img,
			}));
			setImages(newImages);
		}else{
			setImages([]);
		}

	}, [defaultValue])


	const handleAddNewImage = () => {
		setImages((prev) => [...prev, { id: prev.length + 1, url: '' }]);
	};

	const updateHookForm = (value: IImages[]) => {
		const mappedImages = value.map((item) => item.url);
		const dotDivider = mappedImages.join(', ');
		setValue('countdownimagelinks', dotDivider);
	};

	const handleRemoveImage = (id: number) => {
		const newImages = images.filter((image) => image.id !== id);
		setImages(newImages);
		updateHookForm(newImages);
	};

	const handleChangeImage = (id: number, url: string) => {
		const newImages = images.map((image) => {
			if (image.id === id) {
				return { ...image, url };
			}
			return image;
		});

		setImages(newImages);
		updateHookForm(newImages);
	};

	return (
		<Container>
			<Content>
				<Header>
					<h1>Adicione as imagens da viagem:</h1>
					<p>Clique no botão para adicionar os links. É necessário que todos os links sejam do unsplash</p>
				</Header>

				<div>
					<TagButton onClick={() => handleAddNewImage()}>Adicionar uma nova imagem</TagButton>
				</div>
			</Content>

			<AnimatePresence>
				{images?.map((image) => (
					<SingleImage
						imageUrl={image.url}
						key={image.id}
						id={image.id}
						onChange={(newId: number, newUrl: string) => handleChangeImage(newId, newUrl)}
						onRemove={() => handleRemoveImage(image.id)}
					/>
				))}
			</AnimatePresence>
		</Container>
	);
};

export default Images;
