import * as yup from 'yup';

export const validation = yup.object().shape({
	title: yup.string().required('O título é obrigatório'),
	destination: yup.string().required('O destino da viagem é obrigatório'),
	budget: yup.string().optional(),
	description: yup.string().required('Uma breve descrição da viagem é obrigatória'),
	startDay: yup.string().min(2, "Utilize 2 dígitos para o dia").required('Utilize 2 dígitos para o dia'),
	
	startMonth: yup.string().min(2, "Utilize 2 dígitos para o mês").required('Utilize 2 dígitos para o mês'),
	
	startYear: yup.string().min(4, "Utilize 4 dígitos para o ano").required('Utilize 4 dígitos para o ano'),
	endDay: yup.string().min(2, "Utilize 2 dígitos para o dia").required('Utilize 2 dígitos para o dia'),
	
	endMonth: yup.string().min(2, "Utilize 2 dígitos para o mês").required('Utilize 2 dígitos para o mês'),
	
	endYear: yup.string().min(4, "Utilize 4 dígitos para o ano").required('Utilize 4 dígitos para o ano')
});

export const nullValidation = yup.object().shape({});
