import styled from "styled-components";

export const Container = styled.div`
display: flex;
  align-items: center;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  height: 100%;
  gap: 20px;
  overflow-y: scroll;
  width: 100%;
  padding-bottom: 4rem;
  @media (max-width: 700px) {
    height: 100%;
    display: flex;
  }
`;

export const SearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;
  align-items: center;
  max-width: 400px;
  svg {
    margin-right: -10px;
    width: 20px;
  }
`;

export const SeachField = styled.input`
  width: 100%;
  border: none;
  border-bottom: 1px solid black;
  outline: none;
  background-color: transparent;
  padding: 10px;
`;

export const TableContainer = styled.div`
  height: 100%;
  width: 100%;
  background: white;
  border: "none";
  border-radius: 10;
  max-width: 1800px;
  .MuiDataGrid-columnHeaderTitle {
    font-weight: 600;
  }
`;

export const PageSizeSelector = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;

  label {
    font-size: 14px;
  }

  select {
    outline: none;
    padding: 5px 10px 5px 5px;
    border-radius: 5px;

    option {
      height: 20px;
    }
  }
`;

const buttonTransition = "0.2s";

export const IconButton = styled.button`
  background: none;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  gap: 10px;
  color: #F1896E;
  opacity: 0.9;

  cursor: pointer;

  svg {
    width: 18px;
    transition: ${buttonTransition};

    path {
      transition: ${buttonTransition};
      fill: ${({ theme }) => theme.palette.grey.main};
    }
  }

  p {
    transition: ${buttonTransition};
    color: ${({ theme }) => theme.palette.grey.main};
    font-weight: 400;
    font-size: 1.2rem;
  }

  &:hover {
    p {
      color: ${({ theme }) => theme.palette.orange.main};
    }

    svg {
      path {
        fill: ${({ theme }) => theme.palette.orange.main};
      }
    }
  }
`;
export const ButtonWrapper = styled.div<{
  buttonColor?: string;
}>`
  & button {
    background-color: ${({ theme, buttonColor }) =>
    buttonColor ? buttonColor : theme.palette.green.main};
    border: none;
    outline: none;
    display: flex;
    width: fit-content;
    gap: 8px;
    align-items: center;
    width: 100px;
    border-radius: 5px;
    padding: 0.3rem 1rem;
    height: 50px;
    :hover {
      background-color: ${({ theme, buttonColor }) =>
    buttonColor ? theme.palette.orange.main : theme.palette.green._200};
    }
  }
  & {
    svg {
      width: 18px;
      transition: 0.5s;
    }
    span {
      color: white;
      font-size: 16px;
      font-weight: bold;
    }
  }
  :hover {
    svg {
      rotate: 90deg;
    }
  }
  button > h1 {
    font-size: 0.8rem;
  }
`;
export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  background-color: ;
  width: 100%
`;
export const TableButtons = styled.div`
display: flex;
  align-items: center;
  gap: 10px;`;
