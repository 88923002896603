import { url, treatError, defaultHeaders } from "../../api";
import { Document } from "types/data/Document";
export const listUserDocs = async (
  qs: number | undefined
): Promise<Array<Document>> => {
  const df = defaultHeaders();
  const headers = { ...df, mode: "no-cors" };
  return fetch(url(`documents/search?travel_id=${qs}`), {
    headers,
    method: "GET",
  }).then((r) => {
    if (r.ok) return r.json();
    throw new Error(treatError(r));
  });
};

export default listUserDocs;
