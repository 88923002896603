import { AnimatePresence } from "framer-motion";
import React, { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import {
  Text,
  Arrow,
  SubText,
  IconContainer,
  ItemContainer,
  ExpansiveContainer,
} from "../styles";

import { IExpanded, IExpansiveItem } from "../types";
import { NEW_ITEM, useSelectedIdContext } from "context/SelectedIdContext";

const ExpansiveItem = ({ path, text, icon, expanded }: IExpansiveItem) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [open, setOpen] = React.useState(false);
  const [active, setActive] = React.useState(false);
  const { setSelectedId } = useSelectedIdContext();

  React.useEffect(() => {
    if (pathname === path) {
      return setActive(true);
    }
    return setActive(false);
  }, [path, pathname]);

  return (
    <>
      <ItemContainer
        selected={active}
        onClick={() => {
          setSelectedId(NEW_ITEM);
          navigate(path);
        }}
      >
        <IconContainer>{icon}</IconContainer>
        <Text>{text}</Text>
        <Arrow open={open} onClick={() => setOpen(!open)}>
          <p>{">"}</p>
        </Arrow>
      </ItemContainer>

      {open && (
        <ExpansiveContainer>
          <AnimatePresence>
            {expanded.map((item: IExpanded) => (
              <React.Fragment key={item.label}>
                <SubText active={pathname === item.path} onClick={() => navigate(item.path)}>
                  {item.label}
                </SubText>
              </React.Fragment>
            ))}
          </AnimatePresence>
        </ExpansiveContainer>
      )}
    </>
  );
};

export default ExpansiveItem;
