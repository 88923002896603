import styled from "styled-components";
import { motion } from "framer-motion";
export const Container = styled(motion.div)`
  width: 600px;
  height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background-color: white;
  position: absolute;
  z-index: 2;

  left: calc(50% - 300px);
  top: 50%;
  transform: translate(-50%, -50%);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 20px;
  span {
    font-family: ${({ theme }) => theme.font.familly};
    font-size: 20px;
    font-weight: 500;
  }
`;
export const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;

  button {
    border-radius: 15px;
  }
  button > h1 {
    font-size: 1rem;
  }
`;
