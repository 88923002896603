import React, { createContext } from "react";

interface IProvider {
  children: React.ReactNode;
}

export interface IIsLoggedContext {
  isLogged: boolean;
  setIsLogged: React.Dispatch<React.SetStateAction<boolean>>;
}

export const IsloggedContext = createContext<IIsLoggedContext>(
  {} as IIsLoggedContext
);

export const IsLoggedContextProvider = ({ children }: IProvider) => {
  const [isLogged, setIsLogged] = React.useState(false);

  const value = {
    isLogged,
    setIsLogged,
  };

  return (
    <IsloggedContext.Provider value={value}>{children}</IsloggedContext.Provider>
  );
};

export const useIsLoggedContext = () => {
  const context = React.useContext(IsloggedContext);

  if (!context) {
    throw new Error(
      "useProfileContext must be used within a ProfileContextProvider"
    );
  }

  return context;
};
