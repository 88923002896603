import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Container = styled(motion.div)`
	display: flex;
	flex-direction: column;
  padding: 0 10px 0 0;

	p {
		color: red;
		margin-top: 0;
	}

	button {
		height: 100%;
		width: 100%;
	}
`;

type Check = 'ERROR' | 'OK' | null;
const validateCheck = (check: Check) => {
	switch (check) {
		case 'OK':
			return 'border: 2px solid #075D68';
		case 'ERROR':
			return 'border: 2px solid red';
		case null:
			return 'border: 2px solid transparent';
		default:
			return 'border: 2px solid transparent';
	}
};

export const Content = styled.div<{
	check: Check;
}>`
	display: flex;
	flex-direction: row;
	gap: 10px;

	button {
		display: flex;
		max-width: 50px;
		cursor: pointer;
		align-items: center;
		justify-content: center;

		padding: 10px;
		border-radius: 8px;
		border: 2px solid #ccc;
	}

	input {
		width: 100%;
		outline: none;
		padding: 10px;
		transition: 0.5s;
		border-radius: 8px;
		box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.1);

		${({ check }) => validateCheck(check)}
	}
`;
