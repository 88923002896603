import { User } from "types/data/User";
import { url, treatError, defaultHeaders } from "../../api";

export const create = async (user: Partial<User>) => {
  const df = defaultHeaders();
  const headers = { ...df, mode: "no-cors" };
  return fetch(url("users/signup"), {
    headers,
    method: "POST",
    body: JSON.stringify(user),
  }).then((r) => {
    if (r.ok) return JSON.stringify(r);
    throw new Error(treatError(r));
  });
};

export default create;
