import React, { useState } from "react";

import { Icon, Container, StyledInput, StyledButton } from "../styles";

import { IInput } from "../types";
import ModalError from "../ModalError";
import { useLottie } from "lottie-react";
import { eyeJSON } from "assets/animation";
import ErrorMessage from "../ErrorMessage";

interface iPassword<T extends string> extends IInput<T> {
  id?: string;
}

const EyeAnimation = ({
  autoPlay,
  isShowing,
}: {
  autoPlay: boolean;
  isShowing: boolean;
}) => {
  const style = {
    height: 40,
    opacity: !isShowing ? 0.6 : 1,
    transition: "0.3s",
  };

  const options = {
    animationData: eyeJSON,
    loop: autoPlay,
    autoPlay: true,
  };

  const { View } = useLottie(options, style);

  return View;
};

const Password = <T extends string>({
  id,
  size,
  color,
  error,
  border,
  radius,
  register,
  customIcon,
  placeholder,
  ...rest
}: iPassword<T>) => {
  const [showPassword, setShowPassword] = useState(false);
  const [autoPlay, setAutoPlay] = useState(false);

  return (
    <>
      {error && <ErrorMessage error={error} />}
      <Container
        error={error?.message}
        border={border}
        size={size}
        color={color}
        radius={radius}
      >
        {customIcon && (
          <Icon
            initial={{
              opacity: 0,
              x: "-50%",
            }}
            animate={{
              opacity: 1,
              x: "0%",
            }}
            transition={{
              delay: 0.55,
            }}
          >
            {customIcon}
          </Icon>
        )}

        {error?.showModalMessage && <ModalError error={error?.message} />}

        <StyledInput
          {...rest}
          {...register}
          id={id}
          placeholder={placeholder}
          type={showPassword ? "text" : "password"}
        />

        <StyledButton
          onMouseEnter={() => setAutoPlay(true)}
          onMouseLeave={() => setAutoPlay(false)}
          onClick={() => setShowPassword(!showPassword)}
        >
          <EyeAnimation isShowing={showPassword} autoPlay={autoPlay} />
        </StyledButton>
      </Container>
    </>
  );
};

Password.defaultProps = {
  customIcon: undefined,
};

export default Password;
