import React, { useState } from "react";

import { TagButton } from "components/Buttons";
import { useProfileContext } from "context/ProfileContext";

import { Input } from "components/Inputs";

import {
  ProfileSVG,
} from "assets/svg/components";

import {
  InfoTexts,
  Container,
  FormContent,
  PerfilInfos,
  TagButtonContainer,
  InputsWrapper,
  InputContent,
  ImgContainer
} from "./styles";
import useUser, { loadLocalUser, storeLocalUser } from "hooks/useUser";
import { numberToPhone } from "utils/formatters";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema } from "./schema";
import { MaskedInput } from "components/Inputs/Masks";
import { Button } from "components/Buttons";
import { useMutation } from "react-query";
import { update } from "api/requests/admin/update";
import { SignUpData } from "types/data/Admin";
import { IconButton } from "pages/Clients/List/Table/styles";
type Inputs = {
  name: string;
  email: string;
  phone: string;
};

const Main = () => {
  const { AdminData } = useUser();

  const updateAdminMutation = useMutation({
    mutationFn: (adminData: Partial<SignUpData>) => update(adminData),
    onSuccess: (data) => {},
  });
  const [editFields, setEditFields] = useState(false);
  const usersInfo = loadLocalUser();
  const numberFormat = numberToPhone(usersInfo?.phone);
  const { register, handleSubmit, setValue } = useForm<Inputs>({
    resolver: yupResolver(schema),
  });
  
  const onSubmit = async (data: Inputs) => {
    if (AdminData?.token) {
      updateAdminMutation.mutate({
        id: AdminData.id,
        fullname: data.name,
        email: data.email,
        phone: data.phone
      });
      storeLocalUser({
        ...AdminData,
        id: AdminData.id,
        fullname: data.name,
        email: data.email,
        phone: data.phone
      });
      
       setEditFields(false);
    }
  };

  const setInputvalues = () => {
    setValue("name", usersInfo?.fullname || '');
    setValue("phone", numberFormat || "");
    setValue("email", usersInfo?.email || "");
  };
  const handleChangeEditFields = () => {
    setEditFields((old) => !old);
    setInputvalues();
  };

  return (
    <Container
      initial={{
        opacity: 0,
        translateY: 10,
      }}
      animate={{
        opacity: 1,
        translateY: 0,
      }}
    >
      <PerfilInfos
        transition={{ delay: 0.3 }}
        initial={{
          opacity: 0,
          translateY: 10,
        }}
        animate={{
          opacity: 1,
          translateY: 0,
        }}
      >
        
        <ImgContainer>
          <ProfileSVG width={50} height={50}/>
        </ImgContainer>
        
        <InfoTexts>
          <h1>{usersInfo?.fullname}</h1>
          <TagButtonContainer>
            <IconButton
              color="#C74C39"
              onClick={handleChangeEditFields}
            >
              Editar Perfil
            </IconButton>
          </TagButtonContainer>
        </InfoTexts>
      </PerfilInfos>
      <FormContent
        onSubmit={handleSubmit(onSubmit)}
        transition={{ delay: 0.4 }}
        initial={{
          opacity: 0,
          translateY: 10,
        }}
        animate={{
          opacity: 1,
          translateY: 0,
        }}
      >
        <InputsWrapper>
          <InputContent>
            <Input
              label="Nome: "
              id="name"
              register={{ ...register("name") }}
              disabled={!editFields}
              color={editFields ? "white" : ""}
              defaultValue={usersInfo?.fullname}
              size="250px"
              option="account"
            />
          </InputContent>
          <InputContent>
            <MaskedInput
              label="Telefone: "
              id="phone"
              option="phone"
              defaultValue={usersInfo?.phone}
              register={{ ...register("phone") }}
              disabled={!editFields}
              placeholder={usersInfo?.phone}
              color={editFields ? "white" : ""}
              size="250px"
            />
          </InputContent>
        </InputsWrapper>
        
        <Input
          label="Email: "
          register={{ ...register("email") }}
          size="100%"
          color={editFields ? "white" : ""}
          disabled={!editFields}
          defaultValue={usersInfo?.email}
        />
        {editFields && <Button buttonType="submit">Pronto</Button>}
      </FormContent>
    </Container>
  );
};

export default Main;
