import { url, treatError, defaultHeaders } from "../../../api";

export const remove = async (id: number) => {
  const defaultHeadersReceive = defaultHeaders();
  const headers = { ...defaultHeadersReceive, mode: "no-cors" };
  return fetch(url(`checklist/draft/item/${id}`), {
    headers,
    method: "DELETE",
  }).then((r) => {
    if (r.ok) return r.json();
    throw new Error(treatError(r));
  });
};

export default remove;
