import { motion } from "framer-motion";
import styled from "styled-components";

export const Container = styled(motion.div)`
  background-color: white;
  width: 600px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem 3rem;
  height: fit-content;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
`;
export const Content = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const ButtonsWrapper = styled(motion.div)`
  display: flex;
  align-items: center;
  gap: 1rem;
`;
export const HideContent = styled(motion.div)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 9rem;
  span {
    text-align: left;
    max-width: 200px;
    font-weight: 500;
  }
`;
