import styled, { css } from "styled-components";
import { IColor } from "./types";
import { motion } from "framer-motion";

export const StyledButton = styled(motion.div) <{
  color: IColor;
}>`
  button {
    width: 100%;
    padding: 0px 60px;
    cursor: pointer;
    h1 {
      color: ${({ theme }) => theme.palette.white.main};
      font-size: 0.9rem;
      text-transform: capitalize
    }
    input {
      background-color: transparent;
      border: none;
      outline: none;
      color: ${({ theme }) => theme.palette.white.main};
      font-weight: bolder;
      font-size: 0.9rem;
    }
    border: none;
    
    ${({ color }) =>
    color === "green" &&
    css`
        color: ${({ theme }) => theme.palette.green.main};
        background-color: ${({ theme }) => theme.palette.green.main};

        &:hover {
          background-color: ${({ theme }) => theme.palette.green._200};
        }
      `}

      ${({ color }) =>
    color === "darkGreen" &&
    css`
        color: #718472;
        background-color: #718472;
  
        &:hover {
          background-color: #718472;
        }
      `} 

    ${({ color }) =>
    color === "orange" &&
    css`
        color: ${({ theme }) => theme.palette.orange._100};
        background-color: ${({ theme }) => theme.palette.orange._100};

        &:hover {
          background-color: ${({ theme }) => theme.palette.orange.main};
        }
      `}
  }
`;
