import { motion } from "framer-motion";
import styled from "styled-components";

export const Container = styled(motion.div)`
  display: flex;
  align-items: center;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;

  @media (max-width: 700px) {
    height: 100%;
    display: flex;
  }
`;

export const ImgContainer = styled.div`
  border-radius: 100px;
  width: 80px;
  height: 80px;
  border-color: rgb(241, 137, 110);
  border-width: 2px;
  border-style: solid;
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const PerfilInfos = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.3rem;
`;

export const InfoTexts = styled(motion.div)`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  h1 {
    font-size: 2rem;
    text-transform: capitalize
  }
`;

export const TagButtonContainer = styled(motion.div)`
  margin-top: -25px;
`;

export const FormContent = styled(motion.form)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
`;

export const BirthDateContent = styled(motion.div)`
  display: flex;
  flex-direction: column;
`;

export const DateInputs = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 20px;
`;

export const ErrorMessage = styled.span`
  color: red;
`;

export const PasswordWrapper = styled(motion.div)``;

export const ErrorAndInputContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
export const InputsWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 30px;
`;
export const InputContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
