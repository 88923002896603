import styled from "styled-components";
import { motion } from "framer-motion";

export const InlineButtonContainer = styled(motion.div)`
  width: fit-content;
  cursor: pointer;
  user-select: none;
  justify-content: center;
`;

export const InlineButtonLine = styled(motion.div)<{
  bgColor?: string;
}>`
  background-color: ${({ theme, bgColor }) =>
    bgColor ? bgColor : theme.palette.green._100};
  height: 100%;
  width: 100%;
  bottom: 0;
  left: 0;
  position: absolute;
`;

export const InlineButton = styled(motion.button)<{
  size?: string;
  color?: string;
}>`
  border: none;
  cursor: pointer;
  z-index: 3;
  width: fit-content;
  font-weight: medium;
  background: transparent;
  transition: 0.5s;
  font-size: ${({ size }) => (size ? size : "1.1rem")};
  color: ${({ color, theme }) => (color ? color : theme.palette.green.main)};
`;
