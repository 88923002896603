import styled from "styled-components";
import { motion } from "framer-motion";
export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 700px) {
    height: 100%;
    display: flex;
  }
`;
export const Image = styled(motion.img)``;
