import React from "react";

import {
  ProfileContextProvider,
  useProfileContext,
} from "context/ProfileContext";
import { MainPage } from "./Main/indes";

const ProvidedProfile = () => {
  return (
    <ProfileContextProvider>
      <Profile />
    </ProfileContextProvider>
  );
};

const Profile = () => {
  const { editPage } = useProfileContext();
  return <MainPage />;
};

export default ProvidedProfile;
