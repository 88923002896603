import styled from "styled-components";
import Switch, { SwitchProps } from "@mui/material/Switch";
export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 3rem;
  background-color: ${({ theme }) => theme.palette.green.main};
  border-radius: 10px;
`;
export const SpanOption = styled.span<{
  color: string;
  selected?: boolean;
}>`
  transition: 0.5s;
  color: ${({ color }) => color};
  text-transform: uppercase;
  font-weight: 500;
  font-size: ${({ selected }) => (selected ? "22px" : "18px")};
`;
