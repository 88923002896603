import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClientProvider } from 'react-query';

import 'react-toastify/dist/ReactToastify.css';

import Global from './styles/globalStyle';
import RouterComponent from './routes/Router';
import { ReactQueryDevtools } from 'react-query/devtools';
import { themes } from './styles/themes';
import { ThemeProvider } from 'styled-components';
import { queryClient } from './api/queryClient';
import { ToastContainer } from 'react-toastify';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { SelectedIdContextProvider } from 'context/SelectedIdContext';
import { IsLoggedContextProvider } from 'context/IsLoggedContext';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
	<React.StrictMode>
		<>
			<SelectedIdContextProvider>
				<IsLoggedContextProvider>
					<ToastContainer />
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<QueryClientProvider client={queryClient}>
							<ReactQueryDevtools initialIsOpen={false} />
							<ThemeProvider theme={themes}>
								<Global />
								<RouterComponent />
							</ThemeProvider>
						</QueryClientProvider>
					</LocalizationProvider>
				</IsLoggedContextProvider>
			</SelectedIdContextProvider>
		</>
	</React.StrictMode>
);
