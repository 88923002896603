import styled, { css } from "styled-components";

export const Arrow = styled.div<{
  open: boolean;
}>`
  ${({ open }) =>
    open &&
    `
    transform: rotate(90deg);
  `}

  transition: 0.2s;
`;

export const ItemContainer = styled.div<{
  open?: Boolean;
  selected: Boolean;
}>`
  gap: 20px;
  display: flex;
  cursor: pointer;
  transition: 0.4s;
  padding: 8px 5px;
  border-radius: 5px;
  flex-direction: row;
  align-items: center;
  background-color: ${({ open }) => (open ? "green" : "")};

  ${({ selected }) =>
    selected &&
    css`
      background-color: rgba(0, 0, 0, 0.05);
    `};

  h1 {
    color: ${({ theme }) => theme.palette.green.main};
  }
  :hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

export const IconContainer = styled.div`
  width: 40px;
  display: flex;
  justify-content: center;
`;

export const ExpansiveContainer = styled.div`
  margin-left: 4rem;
`;

export const Text = styled.h1``;

export const SubText = styled.h2<{
  active?: boolean;
}>`
  padding: 10px;
  cursor: pointer;

  :hover {
    color: ${({ theme }) => theme.palette.orange.main};
  }

  transition: 0.3s;

  ${({ active }) =>
    active &&
    css`
      background: #F2F2F2;
    `}
`;
