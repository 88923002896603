import { Key } from "react";
import { ActivitiesSearch } from "types/data/ActivitiesSearch";
import { url, treatError, defaultHeaders } from "../../api";

export const search = async (
  key: Key | undefined | number
): Promise<Array<ActivitiesSearch>> => {
  const defaultHeadersReceive = defaultHeaders();
  const headers = { ...defaultHeadersReceive, mode: "no-cors" };
  return fetch(url(`travelActivity/search?activity_id=${key}`), {
    headers,
    method: "GET",
  }).then((r) => {
    if (r.ok) return r.json();
    throw new Error(treatError(r));
  });
};

export default search;
