import { activities } from "types/data/Activities";
import { url, treatError, defaultHeaders } from "../../api";

export const update = async (
  activity: Partial<activities>
): Promise<activities> => {
  const defaultHeadersReceive = defaultHeaders();
  const headers = { ...defaultHeadersReceive, mode: "no-cors" };
  return fetch(url(`activity/${activity.id}`), {
    headers,
    method: "PATCH",
    body: JSON.stringify(activity),
  }).then((r) => {
    if (r.ok) return r.json();
    throw new Error(treatError(r));
  });
};

export default update;
