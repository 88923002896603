import * as yup from "yup";
export const schema = yup.object().shape({
  fullName: yup.string().required("Preencha com o nome completo do cliente"),
  email: yup
    .string()
    .email("E-mail inválido")
    .required("O e-mail é obrigatório"),
  day: yup
    .string()
    .min(2, "Utilize 2 dígitos para o dia")
    .required("Utilize 2 dígitos para o dia"),
  month: yup
    .string()
    .min(2, "Utilize 2 dígitos para o mês")
    .required("Utilize 2 dígitos para o mês"),
  year: yup
    .string()
    .min(4, "Utilize 4 dígitos para o ano")
    .required("Utilize 2 dígitos para o ano"),
  firstPhone: yup
    .string()
    .required("Digite um número de telefone válido"),
});
