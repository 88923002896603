import React from 'react';
import { Container, ScrollBody } from "./styles"

const Legal = () => {
    return (
        <ScrollBody>
            <Container>
                <p><strong>TERMOS E CONDIÇÕES GERAIS DE CONTRATAÇÃO E USO DO APP</strong></p>
                <p>&nbsp;</p>
                <p>Por favor, leia com atenção os termos e condições (“TERMOS DE USO”) abaixo, sob os quais a MARIA CAROLINA GONSALES - MEI, microempresária individual, inscrita no CNPJ sob nº 36.288.233/0001-89, registrada sob o nome fantasia “VOYA HAUS” (doravante denominada simplesmente “VOYA HAUS”), oferece ao USUÁRIO dos serviços (doravante denominado simplesmente “USUÁRIO”), acesso ao software de sua propriedade, através do aplicativo (doravante denominado “APP”) chamado “VOYA HAUS”, disponível para download em aparelhos telefônicos celulares. </p>
                <p>&nbsp;</p>
                <p>Para que o USUÁRIO tenha acesso ao APP, deverá declarar estar de acordo com todos os termos e condições deste TERMO DE USO, sendo que a recusa de assinatura impedirá que o USUÁRIO utilize toda e qualquer funcionalidade do APP.</p>
                <p>&nbsp;</p>
                <p>Os&nbsp;TERMOS DE USO&nbsp;ficam incorporados para todos os fins e efeitos aos contratos celebrados digitalmente para ingresso no APP, bem como em todos os demais documentos, acordos e contratos firmados ou concluídos entre&nbsp;VOYA HAUS&nbsp;e&nbsp;o USUÁRIO.</p>
                <p>&nbsp;</p>
                <p><strong>1. OBJETO</strong></p>
                <p>1.1 O objeto dos&nbsp;TERMOS DE USO&nbsp;e dos contratos eventualmente celebrados entre&nbsp;VOYA HAUS&nbsp;e&nbsp;o USUÁRIO&nbsp;será a prestação de serviços de consultoria e projeto de viagens nacionais e internacionais, mediante utilização do APP denominado “VOYA HAUS”, no qual estarão disponíveis as informações da(s) viagem(ns) previamente planejada(s) e combinada(s) entre as partes, através do(s) contrato(s) firmado(s). O APP também poderá disponibilizar ao USUÁRIO uma área de armazenamento de documentos pessoais e vouchers de viagens, dentre outros arquivos, aos quais a VOYA HAUS não terá acesso e ficará eximida de qualquer responsabilidade. </p>
                <p>1.2. O APP não possui qualquer vinculação com outros APPs ou sites, podendo disponibilizar os seus links para facilitar o acesso a determinados serviços não prestados pela VOYA HAUS. </p>
                <p>&nbsp;</p>
                <p><strong>2. CONDIÇÕES DE USO E OBRIGAÇÕES DO USUÁRIO</strong></p>
                <p>2.1. Competirá à VOYA HAUS o cadastro e habilitação de cada USUÁRIO para o uso do APP, após o recebimento de todos os dados necessários para tanto. A definição da senha do USUÁRIO será feita por ele próprio, através de um link enviado pela VOYA HAUS diretamente para o e-mail indicado pelo USUÁRIO. </p>
                <p>2.2. Para viabilizar o cadastro no APP, o USUÁRIO deverá informar todos os dados necessários para a conclusão do procedimento com posterior validação via e-mail, incluindo a apresentação de documentos que venham a ser exigidos. </p>
                <p>2.3. O cadastramento e utilização do APP são de livre escolha do USUÁRIO, ficando este ciente que a prestação completa e integral dos serviços da VOYA HAUS se dará através do APP, onde toda a viagem estará estruturada com as informações pertinentes sobre o(s) destino(s), itinerário(s), check-lists de documentos e demais detalhes. Caso opte por não utilizar o APP, o USUÁRIO reconhece que a experiência proposta pela VOYA HAUS restará comprometida, não cabendo a ela qualquer tipo de responsabilidade em razão da escolha feita pelo USUÁRIO. </p>
                <p>2.5. É de responsabilidade do USUÁRIO a veracidade, regularidade e atualidade dos documentos e informações apresentados, não sendo VOYA HAUS responsável direta ou indiretamente por qualquer ato do USUÁRIO, incluindo ilícito praticado por esse com relação a eventual fraude e a ilícito de qualquer outra natureza.</p>
                <p>2.6. Para que o USUÁRIO possa utilizar o APP, deverá atender, no mínimo, os seguintes requisitos:</p>
                <p>{"a)"} Possuir aparelho de telefonia móvel, com linha ativa, internet móvel habilitada e apto a suportar a instalação e operação do APP; e</p>
                <p>{"b)"} Ser maior de 18 anos de idade.</p>
                <p>2.7 O&nbsp;USUÁRIO&nbsp;será automaticamente descadastrado, caso identificada conduta de sua autoria em desacordo com os&nbsp;TERMOS DE USO.</p>
                <p>2.8. Fica a critério exclusivo do USUÁRIO a forma como ele usufrui do APP, de forma que tem total liberdade para decidir: </p>
                <p>(i) a escolha do momento em que se conectará ao APP; </p>
                <p>(ii) por quanto tempo ficará disponível no APP;</p>
                <p>(iii) o horário, local e quantidade de acessos ao APP. </p>
                <p>2.9. O USUÁRIO reconhece que não existe qualquer participação da VOYA HAUS nas suas escolhas, podendo acessar e usufruir do APP como melhor lhe couber desde que respeitados os limites e finalidade deste Termo e Condições.</p>
                <p>2.10. O USUÁRIO reconhece e tem consciência de todos os imprevistos e casos fortuitos que podem existir quando do uso do APP da VOYA HAUS, inexistindo qualquer ingerência ou responsabilidade da VOYA HAUS em relação a tais situações imprevisíveis, inclusive, mas não limitada, à expiração dos links utilizados para indicar a localização de pontos turísticos, restaurantes, hotéis, aeroportos/rodoviárias/estações de trem etc. </p>
                <p>&nbsp;</p>
                <p><strong>3. POLÍTICA DE PRIVACIDADE, DIVULGAÇÃO DE INFORMAÇÕES E SO DE DADOS</strong></p>
                <p>3.1. Para a perfeita prestação de seu serviço de consultoria e projeto de viagens, a VOYA HAUS poderá precisar coletar, armazenar, transmitir e/ou disponibilizar a terceiros os dados e informações fornecidos pelo USUÁRIO no momento do seu cadastro, portanto, todas as informações do USUÁRIO poderão ser repassadas a terceiros. Tais informações incluem, mas não se limitam a informações pessoais (número de documentos, inclusive passaporte), sua localização; nome completo; imagem ou foto de seu perfil, dentre outros, não havendo cláusula de confidencialidade sobre tais documentos e informações do USUÁRIO.</p>
                <p>3.2. A utilização do APP implica o consentimento do USUÁRIO para coleta, armazenamento e uso das informações pessoais fornecidas e suas atualizações, dados de tráfego, endereços IP, entre outros.</p>
                <p>3.3. A VOYA HAUS se compromete a manter protegidos todos os dados cadastrais, incluindo e-mail informado, número de IP de acesso e outras informações pessoais que não sejam necessárias à utilização dos Serviços. O USUÁRIO autoriza neste ato a VOYA HAUS a informar e/ou divulgar estes dados em caso de exigência legal, requisições de autoridades policiais ou se razoavelmente necessárias para: </p>
                <p>(i) o devido processo legal; </p>
                <p>(ii) prestar serviços ao USUÁRIO;</p>
                <p>(iii) fazer cumprir este TERMO DE USO; </p>
                <p>(iv) responder a alegações de suposta violação de direitos de terceiros e de divulgação indevida de informações para contato de terceiros, e para proteger os direitos, a propriedade ou a segurança de terceiros, da própria VOYA HAUS e de outros USUÁRIOS; e </p>
                <p>(v) em outras hipóteses que se fizerem necessárias para a efetividade e manutenção e segurança do APP.</p>
                <p>3.4. A VOYA HAUS se reserva o direito de reter informações pelo período que entender necessário para o bom funcionamento da plataforma, mesmo após o encerramento da conta do USUÁRIO.</p>
                <p>3.5. Para viabilizar a consultoria completa de viagem, a VOYA HAUS cederá em comodato, para o USUÁRIO, o uso do APP, sendo vedada a disponibilização de sua senha e seu acesso a terceiro estranho ao contrato firmado entre as partes, salvo quem viajará junto com o USUÁRIO e deseja utilizar o APP, desde que previamente informado à VOYA HAUS, para ela que possa efetuar o seu cadastro. </p>
                <p>&nbsp;</p>
                <p><strong>4. INTERCORRÊNCIAS NO ACESSO DO APP</strong></p>
                <p>4.1. Caso ocorra o uso não autorizado de sua conta, o USUÁRIO deverá notificar para certificar imediatamente a VOYA HAUS, através do endereço de correio eletrônico “maria@voyahaus.com.br”. </p>
                <p>4.2. Caso o USUÁRIO esqueça ou perca a senha ou seu nome de USUÁRIO, esses dados poderão ser recuperados se o USUÁRIO responder adequadamente às verificações de segurança. A VOYA HAUS se reserva o direito de suspender ou cancelar, a seu exclusivo critério, o acesso à conta do USUÁRIO caso entenda haver uma violação de segurança. Por medida de segurança, a VOYA HAUS poderá, a seu exclusivo critério, exigir que o USUÁRIO mude todas e quaisquer das senhas usadas para acessar o APP.</p>
                <p>4.3. O USUÁRIO poderá solicitar a alteração, a qualquer momento, das informações de seu cadastro ou solicitar o seu cancelamento.</p>
                <p>4.4. O USUÁRIO é inteiramente responsável por todo e qualquer ato praticado no uso do APP que ocorrerem em seu login e senha, pelo que se compromete a não fornecer seus dados de acesso ao APP a ninguém, sob pena de apuração da responsabilidade legal cabível, cobrança de eventuais perdas e danos cabíveis e imediata exclusão do cadastro no APP.</p>
                <p>&nbsp;</p>
                <p><strong>5. INEXISTÊNCIA DE GARANTIAS</strong></p>
                <p>5.1. O USUÁRIO declara ter plena ciência de que a VOYA HAUS não dará qualquer garantia a respeito do funcionamento do APP, em especial que (i) o uso do APP será ininterrupto, seguro ou isento de erros; ou (ii) o uso do APP proporcione qualquer performance ou atenda a qualquer expectativa. Caso o APP pare de funcionar e o USUÁRIO estiver em viagem, compete ao USUÁRIO informar imediatamente a VOYA HAUS, através do e-mail <u><a href="mailto:maria@voyahaus.com.br">maria@voyahaus.com.br</a></u> e telefone celular (47) 99786-0632 (whatsapp e ligação), para que essa possa tomar as providências cabíveis. </p>
                <p>5.2. A VOYA HAUS não tem qualquer responsabilidade direta ou indireta sobre qualquer comentário ou avaliação eventualmente disponibilizado no APP, pelo que o USUÁRIO, desde já, isenta a VOYA HAUS de quaisquer reclamações, danos ou prejuízos decorrentes destes conteúdos.</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p><strong>6. INEXISTÊNCIA DE VÍNCULO TRABALHISTA</strong></p>
                <p>6.1. O USUÁRIO e a VOYA HAUS têm total ciência de que a relação entre elas não possui nenhuma das características previstas em lei ou jurisprudência para reconhecimento do vínculo empregatício, tratando-se de relação estritamente cível de prestação de serviços de consultoria e estruturação de viagem pela VOYA HAUS.</p>
                <p>6.2. As partes deste instrumento são independentes entre si e cada uma é inteiramente responsável por suas despesas, taxas, contribuições e impostos. </p>
                <p>&nbsp;</p>
                <p><strong>7. MODIFICAÇÕES DOS TERMOS DE USO</strong></p>
                <p>7.1. Independentemente de qualquer notificação ao USUÁRIO, os&nbsp;TERMOS DE USO&nbsp;poderão, a qualquer tempo, ter seu conteúdo, ou parte dele, modificados para adequações e inserções, tudo com vistas ao aprimoramento dos serviços e produtos disponibilizados, sem que caiba qualquer tipo de indenização ou ônus ao USUÁRIO.</p>
                <p>7.2. As novas condições entrarão em vigência assim que veiculadas no&nbsp;APPE&nbsp;e informadas ao&nbsp;USUÁRIO, sendo possível a este manifestar oposição às modificações, desde que o faça no&nbsp;APP, o que irá ocasionar o cancelamento de seu cadastro.</p>
                <p>7.3. As modificações entrarão em vigor na data da publicação no APP e, ao continuar utilizando os serviços prestados por meio do APP, o USUÁRIO automaticamente concorda e se sujeita a eles.</p>
                <p>&nbsp;</p>
                <p><strong>8. PROPRIEDADE INTELECTUAL</strong></p>
                <p>8.1 Todos e quaisquer direitos de propriedade intelectual relativos ao&nbsp;APP&nbsp;pertencem única e exclusivamente à&nbsp;VOYA HAUS, incluindo, mas não se limitando a marcas, logotipos, símbolos, nomes de domínio, trade dress, designs, desenhos industriais, imagens, fotografias, fundos de tela, ícones, ferramentas de navegação, textos, dentre outros, presentes no APP. Em nenhuma hipótese, os&nbsp;TERMOS DE USO&nbsp;ou os contratos implicarão transferência, no todo ou em parte, de qualquer direito de propriedade intelectual ou industrial ao&nbsp;USUÁRIO.</p>
                <p>8.2 O&nbsp;USUÁRIO&nbsp;se compromete a utilizar o&nbsp;APP&nbsp;de acordo com as suas finalidades e exigências técnicas; dispor de meios adequados para a implantação e a utilização do&nbsp;<em>software</em>, incluindo, sem limitação, hardware, rede e pessoal adequados etc.; responsabilizar-se legalmente por quaisquer dados e informações que venham a ser armazenados; não fazer ou distribuir quaisquer cópias do&nbsp;<em>software</em>; não alterar, combinar, adaptar, traduzir, decodificar, fazer ou solicitar a terceiros engenharia reversa do&nbsp;<em>software</em>; não criar trabalho dele derivado ou solicitar que terceiros o façam; e não ceder, licenciar, sublicenciar ou de qualquer outra forma dispor do&nbsp;<em>software</em>.</p>
                <p>&nbsp;</p>
                <p><strong>9. AUTORIZAÇÃO DE CESSÃO DE DIREITOS DE IMAGEM</strong></p>
                <p>9.1. O USUÁRIO, neste ato, promove a cessão e transferência à VOYA HAUS dos direitos de uso de sua imagem eventualmente usada em campanhas e eventos produzidos e/ou patrocinados pela VOYA HAUS, atestando que entende como imagem qualquer forma de representação, inclusive fotográfica, bem como o processo audiovisual que resulta da fixação de imagens com ou sem som, que tenha a finalidade de criar, por meio de sua reprodução, a impressão de movimento, independentemente dos processos de sua captação, do suporte usado inicial ou posteriormente para fixá-lo, bem como dos meios utilizados para sua veiculação.</p>
                <p>9.2. Declara o USUÁRIO ainda que essa cessão é feita em caráter universal, total e definitiva, por prazo indeterminado e a título gratuito, produzindo efeitos não só no Brasil, mas em qualquer lugar situado também fora das fronteiras nacionais.</p>
                <p>9.3. O USUÁRIO também autoriza a VOYA HAUS a utilizar a título gratuito ou oneroso a imagem cedida, no Brasil ou no exterior, sem qualquer limitação de tempo ou da modalidade de utilização, sem que caiba ao mesmo qualquer participação no eventual proveito econômico que direta ou indiretamente a VOYA HAUS venha a auferir, sendo que esse ajuste produzirá efeitos inclusive em relação aos eventuais herdeiros e sucessores do USUÁRIO.</p>
                <p>&nbsp;</p>
                <p><strong>10. CONSIDERAÇÕES FINAIS</strong></p>
                <p>10.1. O USUÁRIO não poderá transferir, ceder ou onerar os direitos e obrigações decorrentes destes Termos e Condições sem a anuência escrita da VOYA HAUS.</p>
                <p>10.2. As partes elegem a Comarca de Balneário Camboriú/SC, para dirimir quaisquer controvérsias oriundas, direta ou indiretamente, destes Termos e Condições, excluindo-se qualquer outro por mais privilegiado que seja.</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
            </Container>
        </ScrollBody>)
}

export default Legal