import { CheckSVG } from "assets/svg/components/Check";
import React from "react";
import { Container, HiddenCheckbox, StyledCheckbox } from "./style";
type Props = {
  checked: boolean;
  handleCheckboxChange?: () => void;
};
export default function CheckboxStyled({
  checked,
  handleCheckboxChange,
}: Props) {
  return (
    <Container
      whileTap={{ scale: 0.8 }}
      checked={checked}
      onClick={handleCheckboxChange}
    >
      <HiddenCheckbox onChange={handleCheckboxChange} checked={checked} />
      <StyledCheckbox checked={checked}>
        <CheckSVG />
      </StyledCheckbox>
    </Container>
  );
}
