import React from "react";

export const PlusSVG = () => {
  return (
    <svg
      width="34"
      height="33"
      viewBox="0 0 34 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33.208 19.072H19.576V32.896H14.136V19.072H0.568V14.144H14.136V0.255997H19.576V14.144H33.208V19.072Z"
        fill="white"
      />
    </svg>
  );
};
