import React, { createContext } from "react";

interface IProvider {
  children: React.ReactNode;
}

export interface IProfileContext {
  editPage: boolean;
  handleShowEditPage: () => void;
  handleShowMainPage: () => void;
}

export const ProfileContext = createContext<IProfileContext>({} as IProfileContext);

export const ProfileContextProvider = ({ children }: IProvider) => {
  const [editPage, setEditPage] = React.useState(false);

  const handleShowEditPage = () => {
    return setEditPage(true);
  };

  const handleShowMainPage = () => {
    return setEditPage(false);
  };

  const value = {
    editPage,
    handleShowEditPage,
    handleShowMainPage,
  };

  return (
    <ProfileContext.Provider value={value}>
      {children}
    </ProfileContext.Provider>
  );
}

export const useProfileContext = () => {
  const context = React.useContext(ProfileContext);

  if (!context) {
    throw new Error("useProfileContext must be used within a ProfileContextProvider");
  }

  return context;
}
