import React, { useEffect, useState } from "react";
import { ErrorAndInputContainer, InputLabel } from "../../style";
import { Input } from "components/Inputs";
import {
  TipsSection,
  SectionTitle,
  SwitchWrapper,
} from "./style";
import ListItem from "components/ListItem";
import { useForm } from "react-hook-form";
import { TipList } from "../../types";
import { InputsForm, Props } from "./types";
import { Button } from "components/Buttons";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { create } from "api/requests/tip/create";
import { search } from "api/requests/tip/search";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { queryClient } from "api/queryClient";
import { tip } from "types/data/Tip";
import { remove } from "api/requests/tip/remove";
import { update } from "api/requests/tip/update";
import Switch from "components/Inputs/Switch";
import { AddButtonWrapper } from "pages/Travel/Register/style";
import { useSelectedIdContext } from "context/SelectedIdContext";

const Tips = ({
  handleMoveItemDown,
  handleMoveItemUp,
  disable,
}: Props) => {

  const schema = yup.object().shape({
    tipTitle: yup.string().required("digite um titulo pra dica"),
    tipDescription: yup.string(),
  });

  const [tipList, setTipList] = useState<tip[]>();
  const [editorValue, setEditorValue] = useState("");
  const [isTipEdit, setIsTipEdit] = useState<boolean>(false);
  const [editTipId, setEditTipsId] = useState<number>();
  const { selectedId } = useSelectedIdContext();
  const [travelId] = useState(Number(selectedId));

  const {
    register,
    getValues,
    handleSubmit,
    setValue,
    reset,
    trigger,
    setError,
    formState,
    formState: { errors, isSubmitSuccessful },
  } = useForm<InputsForm>({
    resolver: yupResolver(schema),
  });

  const handleEditTip = (id: number) => {
    const filteredData = tipList?.find((item) => item.id === id);
    if (filteredData) {
      if (filteredData.title) {
        setValue("tipTitle", filteredData?.title);
      }
      if (filteredData.content) {
        setEditorValue(filteredData.content);
      }
      setValue("isvisible", filteredData?.isvisible || false);
      setEditTipsId(id);
      setIsTipEdit(true);
      toggleVisibility(filteredData?.isvisible || false)
    }

  };

  const tipListMutation = useMutation({
    mutationFn: () => search(travelId),
    onSuccess: (data) => {
      setTipList(data);
    },
    onError: (error) => {
      toast.error(`Algo deu errado ao buscar a lista: ${error}`);
    },
  });

  const travelTips = useMutation({
    mutationFn: (item: tip) => create(item),

    onSuccess: (data) => {
      tipListMutation.mutate();
      toast.success('Dica criada')
    },
    onError: (error) => {
      toast.error(`Algo deu Errado ${error}`);

    },
  });
  const editTipMutation = useMutation({
    mutationFn: (item: tip) => update(item),

    onSuccess: (data) => {
      tipListMutation.mutate();
      toast.success("Dica Editada");
    },
    onError: (error) => {
      toast.error(`Algo deu Errado ${error}`);
    },
  });
  const removeTip = useMutation({
    mutationFn: (id: number) => remove(id),

    onSuccess: (data) => {
      tipListMutation.mutate();
      toast.success("Dica deletada");
    },
    onError: (error) => {
      toast.error(`Algo deu Errado ${error}`);
    },
  });

  const handleAddTip = async () => {
    try {
      const onTrigger = await trigger(["tipTitle", "tipDescription"]);
      if (onTrigger) {
        const tipTitle = getValues("tipTitle");

        const tipDescription = editorValue;
        const tipDescriptionFormat = tipDescription.replace(/[\r\n]+/gm, "");

        const tipFormat: tip = {
          title: tipTitle,
          content: tipDescriptionFormat,
          travel_id: travelId,
          isvisible: isVisible,
        };
        if (isTipEdit) {
          const newTip = { ...tipFormat, id: editTipId };
          editTipMutation.mutate(newTip);
        } else {
          travelTips.mutate(tipFormat);

        }

        setValue("tipTitle", "");
        setEditorValue("");
        toggleVisibility(false)
      }
    } catch (e) {
      toast.error(`Erro: ${e}`)
    }
  };
  const removeTipListItem = (id: number) => {
    removeTip.mutate(id);
  };
  const handleMoveTipItem = (id: number, direction: "up" | "down") => {
    // switch (direction) {
    //   case "up":
    //     handleMoveItemUp(id, tipList, setTipList);
    //     break;
    //   case "down":
    //     handleMoveItemDown(id, tipList, setTipList);

    //     break;
    //   default:
    //     break;
    // }
  };

  useEffect(() => {
    tipListMutation.mutate();
  }, [travelId]);

  const [isVisible, toggleVisibility] = useState<boolean>(getValues('isvisible') || false)

  return (
    <TipsSection>
      <SectionTitle>Dicas:</SectionTitle>
      <ErrorAndInputContainer>
        <Input
          error={{
            message: errors.tipTitle?.message,
            showMessage: true,
          }}
          color={disable ? "" : "white"}
          placeholder="Titulo da Dica"
          label="Titulo da Dica"
          type="normal"
          register={{ ...register("tipTitle", { required: true }) }}
          disable={disable}
          size="500px"
        />

        <SwitchWrapper>
          <InputLabel>Visibilidade: </InputLabel>
          <Switch
            checked={isVisible}
            handleChange={() => toggleVisibility(!isVisible)}
            options={["Invisível", "Visível"]}
          />
        </SwitchWrapper>

        <AddButtonWrapper>
          <Button
            onClick={() => {
              handleAddTip()
            }}
          >Salvar Dica</Button>
        </AddButtonWrapper>
      </ErrorAndInputContainer>

      <InputLabel>Descrição da dica: </InputLabel>

      <ReactQuill theme="snow" value={editorValue} onChange={setEditorValue} />;

      {tipList && (
        <div>
          {tipList?.map((item, index) => {
            return (
              <ListItem
                handleEdit={handleEditTip}
                type="titleDescriptionList"
                handleRemoveListItem={removeTipListItem}
                handleMoveItem={handleMoveTipItem}
                title={item.title ? item.title : ""}
                description={item.content}
                key={item.id}
                isVisible={item.isvisible}
                itemId={item.id ? item.id : index}
              />
            );
          })}
        </div>
      )}
    </TipsSection>
  );
};
export default Tips;
