import { defaultHeaders, treatError, url } from "api/api";
import { checklist_item } from "types/data/ChecklistItem";

export const update = async (
  checklistItem: checklist_item
): Promise<checklist_item> => {
  const defaultHeadersReceive = defaultHeaders();
  const headers = { ...defaultHeadersReceive, mode: "no-cors" };
  return fetch(url(`checklist/draft/item`), {
    headers,
    method: "PATCH",
    body: JSON.stringify(checklistItem),
  }).then((r) => {
    if (r.ok) return r.json();
    throw new Error(treatError(r));
  });
};

export default update;
