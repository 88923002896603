import React from "react";
import { useNavigate } from "react-router-dom";
import { IconContainer, ItemContainer, Text } from "../styles";
import { ISimpleItem } from "../types";
import { useLocation } from "react-router-dom";

const SimpleItem = ({ text, icon, path }: ISimpleItem) => {
  const [active, setActive] = React.useState(false);

  const navigate = useNavigate();
  const { pathname } = useLocation();

  React.useEffect(() => {
    const verifyLocation = () => {
      if (pathname === path) {
        return setActive(true);
      }
      return setActive(false);
    };

    verifyLocation();
  }, [path, pathname]);

  return (
    <ItemContainer onClick={() => navigate(path)} selected={active}>
      <IconContainer>{icon}</IconContainer>
      <Text>{text}</Text>
    </ItemContainer>
  );
};

export default SimpleItem;
