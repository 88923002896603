import Title from '../../components/Title';
import { InputPassword } from 'components/Inputs';
import { Button } from 'components/Buttons';
import { passwordICON } from '../../assets/svg/asset';
import { Container, ButtonContainer, FormBox, PasswordRulesContainer, PasswordRulesText, InputContainer } from './style';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { registerPassword } from 'api/requests/user/registerPassword';

import { schema } from './schema';
import { toast } from 'react-toastify';
import { useMutation } from 'react-query';
import { useLocation } from 'react-router-dom';
import { useState } from 'react';
import { regexLowercase, regexSpecialChar, regexUppercase } from 'utils/regex';

interface IFormValues {
	firstPassword: string;
	secondPassword: string;
}

const PassIcon = () => (
	<img
		src={passwordICON}
		alt='icone de senha do usuário'
	/>
);

const RedefinePassword = () => {
	const { search } = useLocation();
	const token = search.split('=')[1];

	const {
		register,
		handleSubmit,
		formState: { errors }
	} = useForm<IFormValues>({
		resolver: yupResolver(schema)
	});

	const { mutate } = useMutation({
		mutationFn: (email: string | undefined) => {
			const response = registerPassword(email, token);
			return response;
		},
		onSuccess: () => {
			toast.success('Senha enviada');
		},
		onError: () => {
			toast.error('Algo deu Errado');
		}
	});

	const onSubmit = (data: IFormValues) => {
		let firstPassword = data.firstPassword;
		let secondPassword = data.secondPassword;
		if (firstPassword === secondPassword) {

			mutate(data.firstPassword);
		} else {
			toast.error('As senhas precisam ser iguais')
		}
	};

	const [sizeValidation, toggleSizeValidation] = useState(false);
	const [hasUpperCase, togglelHasUpperCase] = useState(false);
	const [hasLowerCase, togglelHasLowerCase] = useState(false);
	const [hasSpecialChar, toggleHasSpecialChar] = useState(false);

	const handleValidation = (text: string) => {
		toggleSizeValidation(text.length >= 8 && text.length <= 32)
		togglelHasUpperCase(RegExp(regexUppercase).test(text))
		togglelHasLowerCase(RegExp(regexLowercase).test(text))
		toggleHasSpecialChar(RegExp(regexSpecialChar).test(text))
	}

	return (
		<Container>
			<FormBox onSubmit={handleSubmit(onSubmit)}>
				<Title>Cadastrar Senha</Title>
				<PasswordRulesContainer>
					{!sizeValidation && <PasswordRulesText>- A senha precisa ter entre 8 e 32 caracteres</PasswordRulesText>}
					{!hasLowerCase && <PasswordRulesText>- A senha precisa ter pelo menos uma letra minúscula (Ex: a, b, c)</PasswordRulesText>}
					{!hasUpperCase && <PasswordRulesText>- A senha precisa ter pelo menos uma letra maiúscula (Ex: A, B, C)</PasswordRulesText>}
					{!hasSpecialChar && <PasswordRulesText>- A senha precisa ter pelo menos um caractere especial (Ex: $,&,*)</PasswordRulesText>}
				</PasswordRulesContainer>
				<InputContainer>
					<InputPassword
						error={{
							showMessage: true,
							message: errors.firstPassword?.message,
							showModalMessage: true
						}}
						color='#E1D3B9'
						size='85%'
						type='normal'
						placeholder='Senha'
						radius={10}
						register={register('firstPassword', { required: true, onChange: (e) => handleValidation(e.target.value) })}
					/>
				</InputContainer>
				<InputContainer>
					<InputPassword
						color='#E1D3B9'
						error={{
							showMessage: true,
							message: errors.secondPassword?.message,
							showModalMessage: true
						}}
						radius={10}
						type='normal'
						placeholder='Senha'
						size='85%'
						register={register('secondPassword', { required: true })}

					/>
				</InputContainer>
				<ButtonContainer>
					<Button color='darkGreen' buttonType='submit'>Pronto</Button>
				</ButtonContainer>
			</FormBox>
		</Container>
	);
};
export default RedefinePassword;
