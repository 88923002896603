import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import * as yup from "yup";

export const schema = yup.object().shape({
  fullName: yup.string(),
  email: yup.string().email("E-mail inválido"),
  day: yup.string().min(2, "Utilize 2 dígitos para o dia"),
  month: yup.string().min(2, "Utilize 2 dígitos para o mês"),
  year: yup.string().min(4, "Utilize 4 dígitos para o ano"),
  firstPhone: yup.string().min(13, "Dígite um número de telefone válido"),
  emergencyContactName: yup.string(),
  emergencyContactPhone: yup
    .string()
    .optional(),
  emergencyContactEmail: yup.string(),
});

export const travelColumns: GridColDef[] = [
  { field: "title", headerName: "Titulo", width: 280 },
  { field: "destination", headerName: "Destino", width: 250 },
  { field: "users", headerName: "Nº de Participantes", width: 400, align: "center" },
  { field: "startdate", headerName: "Inicio", width: 150 },
  { field: "enddate", headerName: "Fim", width: 200 },
  {
    field: "status",
    headerName: "Status",
    width: 100,
    renderCell: (params: GridRenderCellParams<string>) => {
      const paramsValue = params.value;
      const handleFieldColor = () => {
        switch (paramsValue) {
          case "Realizada":
            return "#075D68";
            break;

          case "Em progresso":
            return "#F1896E";
            break;

          case "Agendada":
            return "#646464";
            break;
        }
      };
      return (
        <strong>
          <>
            <span
              style={{ color: handleFieldColor() }}
              tabIndex={params.hasFocus ? 0 : -1}
            >
              {params.value}
            </span>
          </>
        </strong>
      );
    },
  },
];
