import React, {
  Dispatch,
  Key,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { useQuery } from "react-query";
import {
  CloseButtonWrapper,
  Container,
  Header,
  ItemsWrapper,
} from "./style";
import { search } from "api/requests/rating/search";
import { RatingListItem } from "./RatingListItem";
import { Rating as IRating } from "types/data/Rating";
import { Button } from "components/Buttons";
import { AnimatePresence } from "framer-motion";
import { ActivitiesSearch } from "types/data/ActivitiesSearch";
import { queryClient } from "api/queryClient";
import { toast } from "react-toastify";
type Props = {
  activityId: Key | undefined;
  setCloseModal: Dispatch<SetStateAction<boolean>>;
  modalIsOn: boolean;
};
const defaultRating = [
  {
    user_id: 2,
    activity_id: 1,
    starrating: 5,
    comment: "adorei",
    cost: "200",
  },
];
const Rating = ({ activityId, setCloseModal, modalIsOn }: Props) => {
  const [ratingData, setRatingData] = useState<IRating[]>(defaultRating);
  const [activityData, setactivityData] = useState<ActivitiesSearch>();
  const query = useQuery("activity-rating", () => search(activityId), {
    refetchOnMount: true,
    onSuccess: (data) => {
      data.map((item) => {
        setactivityData(item);
      });
    },

    onError: (e) => toast.error(`Erro: ${e}`),
  });
  useEffect(() => {
    queryClient.invalidateQueries("activity-rating");
  }, [activityId]);
  return (
    <>
      <AnimatePresence>
        {modalIsOn && (
          <Container
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0.3 }}
          >
            <Header
              initial={{ y: 10 }}
              animate={{ y: 0 }}
              exit={{ y: -10, opacity: 0 }}
            >
              <h1>Avaliações</h1>
            </Header>
            <ItemsWrapper>
              {activityData?.activities.rating.map((item, index) => {
                return (
                  <RatingListItem
                    key={index}
                    comment={item?.comment}
                    starRating={item?.starrating}
                    user_id={item.user_id}
                  />
                );
              })}
            </ItemsWrapper>
            <CloseButtonWrapper
              onClick={() => setCloseModal(false)}
              initial={{ opacity: 0, scale: 0 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{
                delay: 0.1,
                duration: 0.1,
                type: "spring",
                stiffness: 100,
              }}
              exit={{ opacity: 0, scale: 0.5 }}
            >
              <Button>OK</Button>
            </CloseButtonWrapper>
          </Container>
        )}
      </AnimatePresence>
    </>
  );
};

export default Rating;
