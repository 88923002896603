import React from "react";
import Box from "../../components/Box";
import Title from "../../components/Title";
import { Input } from "components/Inputs";
import { Button } from "components/Buttons";
import { TagButton } from "components/Buttons";
import { passwordICON, profileICON } from "../../assets/svg/asset/index";
import {
  Container,
  TagButtonContainer,
  TextContent,
  ButtonContainer,
} from "./style";
const ForgotPassword = () => {
  const ClientsIcon = () => (
    <img src={profileICON} alt="icone do perfil de usuário" />
  );
  return (
    <Container>
      <Box isShort>
        <Title>Esqueci a senha</Title>
        <TextContent>
          <span>
            Informe seu email de cadastro e lhe enviaremos um link com as
            instruções.
          </span>
        </TextContent>
        <Input
          type="normal"
          placeholder="E-mail"
          customIcon={<ClientsIcon />}
        />
        <ButtonContainer>
          <Button>Pronto</Button>
        </ButtonContainer>
        <TagButtonContainer>
          <TagButton>Cancelar</TagButton>
        </TagButtonContainer>
      </Box>
    </Container>
  );
};
export default ForgotPassword;
