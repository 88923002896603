import React from "react";
import { logoIMG } from "assets/img/index";
import { Container, Image } from "./styles";
const Home = () => {
  return (
    <Container>
      <Image
        src={logoIMG}
        alt="Voya Haus Logo"
        initial={{ opacity: 0, scale: 0 }}
        animate={{
          opacity: 1,
          scale: 1,
          transition: { type: "spring", stiffness: 230, duration: 2 },
        }}
        exit={{ opacity: 0, scale: 0 }}
      />
    </Container>
  );
};

export default Home;
