import styled from "styled-components";
import { motion } from "framer-motion";

export const StyledInput = styled(motion.input)<{
  disable?: boolean;
}>`
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  background-color: transparent;
  color: ${({ theme }) => theme.palette.grey.main};
  &::placeholder {
    color: white
  }
  &[type="date"] ::-webkit-inner-spin-button {
    display: none;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  `;

export const InputLabel = styled.p`
  padding: 0px;
  margin: 0px;
  font-size: 20px
`


export const Container: any = styled(motion.div)<{
  size?: string;
  radius?: number;
  color?: string;
  border?: string;
  error?: string;
}>`
  width: ${({ size }) => (size ? size : "")};
  position: relative;
  display: flex;
  flex-direction: row;
  border: none;
  align-items: center;
  user-select: none;
  align-items: center;
  transition: border 0.3s;
  background-color: ${({ theme, color }) =>
    color ? color : theme.palette.orange._200};
  border-radius: ${({ radius }) => (radius ? radius : 100)}px;
  height: 45px;
  padding: 0px 25px;
  
`;

export const StyledButton = styled(motion.div)`
  border: none;
  cursor: pointer;
  height: 40px;
  width: 40px
`;

export const Icon = styled(motion.div)`
  * {
    width: 25px;
    height: 25px;
    max-width: 35px;
    max-height: 35px;
  }
`;
