import React from "react";
import { url, treatError, defaultHeaders } from "../../api";

export const updateTravelUsers = async (
  id: number,
  data: { add: number[] } | { remove: number[] | React.Key[] | undefined }
): Promise<boolean> => {
  const defaultHeadersReceive = defaultHeaders();
  const headers = { ...defaultHeadersReceive, mode: "no-cors" };
  return fetch(url(`travels/${id}/users`), {
    headers,
    method: "PATCH",
    body: JSON.stringify(data),
  }).then((r) => {
    if (r.ok) return r.ok;
    throw new Error(treatError(r));
  });
};

export default updateTravelUsers;
